import React from 'react'
import cls from 'classnames'
import styles from '../cards.module.scss'
import { useTranslation } from 'react-i18next'

const NewCardView = ({onAddCard}) => {
  const { t } = useTranslation();
  return  (
    <div className={cls(styles.card, styles.add)} onClick={onAddCard}>
      <div className={styles.name}>{t('add-card-title')}</div>
      <div className={styles.cover}></div>
      <div className={styles.controls}>
        <div></div>
        <div></div>
      </div>
    </div>
  )
}
 
export default NewCardView