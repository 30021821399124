import React, { useEffect } from 'react'
import Loader from '../../components/elements/loader-vintage'
import * as Queries from '../../queries/authorization'  
import { useTranslation } from 'react-i18next'
import '../../styles/text.scss'

const TextPageContainer = ({pageName}) => {
  const { i18n } = useTranslation();
  const { data, isLoading, refetch } = Queries.useGetHTML(pageName, i18n.resolvedLanguage)
     
  useEffect(()=>{
    refetch()
  }, [i18n.resolvedLanguage, pageName, refetch])

  return (
          <>
            {
              !isLoading && <div className="text-container" dangerouslySetInnerHTML={{ __html: data }} />
            }
            <Loader enabled={isLoading}/>
          </>
  )   
}

export default TextPageContainer
