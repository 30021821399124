import React from 'react'
import Header from '../organizms/header'
import Footer from '../organizms/footer'
import cls from 'classnames'

export default function Layout({ children, isAuthorized, restricted = false, fullHeight = false, ...props }) {
  return (
    <div className="wrapper">
      <Header  isAuthorized={isAuthorized} restricted={restricted}/>
      {
        typeof isAuthorized !== 'undefined' && <div className={cls("container-fluid", fullHeight ? "full":"")}>
                                                 <div className="content">{React.cloneElement(children, { isAuthorized: isAuthorized } )}</div>
                                               </div>
      }
      <Footer isAuthorized={isAuthorized} restricted={restricted}/>
    </div>
  )
}