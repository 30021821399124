import React from 'react'
import * as Queries from '../../queries/authorization'  


const LogoutPageContainer = () => {

  Queries.useLogoutUser()

  return (
          <>

          </>
  )
}

export default LogoutPageContainer
