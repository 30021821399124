const schema = () => { return {
    title: 'otp',
    
    fields: [{ 
      component: 'otp-field',
      type: 'password',
      name: 'OTP',
      autoFocus: true,
      caption: 'otp',
      phone: true,
      className: 'left'
    },{
      component: 'masked-pan-field',
      type: 'password',
      name: 'CardNumber',
      autoFocus: true,
      caption: 'masked-pan',
      className: 'left'
    },{
      component: 'cvv-field',
      type: 'password',
      name: 'SecureCode',
      autoFocus: true,
      caption: 'cvv',
      className: 'left'
    }],
    submitLabel: 'enter'
  }}

export default schema