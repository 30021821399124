import { useState, useEffect } from 'react';
import { useQueryClient } from 'react-query'
import {useAuthorizeUser} from '../../queries/authorization'  

export const useAuthorization = () => {
  const queryClient = useQueryClient()
  const authorized = queryClient.getQueryData("Authorized")
  const [ isAuthorized, setAuthorized ] = useState(authorized)
  const { mutate: auth } = useAuthorizeUser()
  const queryCache = queryClient.getQueryCache()
  
  useEffect(() => {
    if(typeof isAuthorized === 'undefined') {
      auth(null, {
        onSuccess: (data)=>{
          setAuthorized(data.Success)
        },
        onError: ()=>{
          setAuthorized(false)
        }
      })        
    } else {
      setAuthorized(authorized)
    }
     
  }, [isAuthorized, authorized, auth]); 

  useEffect(()=>{
    const callback = event => {
      if (event.query.queryKey === 'Authorized' && event.query.state.status === 'success') {
        setAuthorized(queryClient.getQueryData("Authorized"))      
      }
    }
    
    const unsubscribe = queryCache.subscribe(callback)

    return () => {
        unsubscribe()
    }
    // eslint-disable-next-line
  }, [])

  return isAuthorized
}
