import { useTranslation } from 'react-i18next';
import styles from '../form.module.scss'
import controlStyles from '../../elements/form-controls/controls.module.scss'
import schema from '../../../schemas/forms/feedback'
import PhoneField from '../../elements/form-controls/phone-field'
import TextAreaField from '../../elements/form-controls/text-area-field'
import { useEffect, useState } from 'react'
import Caption from '../control-caption'
import useInput from '../control-state-hook'
import cls from 'classnames'
import { useIMask } from 'react-imask'

const Patterns = {
  'email-field': /^\w+([\\.+-]?\w+)*@\w+([\\.-]?\w+)*(\.\w{2,4})+$/,
  'phone-field' : /^[0-9xX]{9}/g,
  'theme-field' : /.{1,}/,
  'text-area-field' : /.{1,}/,
}

const Form = ({...props}) => {
  const { t, i18n } = useTranslation();
  const [ btnState, setBtnState ] = useState(false);
  const [ error, setError ] = useState({});
  const [ blur, setBlur ] = useState({});
  const { ref: refEmail } = useIMask({ mask: /^\S*@?\S*$/ });
  const { ref: refTheme } = useIMask({ mask: /^\S*$/ });

  const phone = useInput('');
  const message = useInput('');

  const handleValidate = (e, type) => {
    setError({...error, [type]:!Patterns[type].test(e.target.value.replace(/ /g, ''))});
  }

  const handleBlur = (type) => {
    setBlur({...blur, [type]:true});
  }

  useEffect(()=>{
    setBtnState((Object.values(error)).length === 4 && (Object.values(error)).every(elem => elem === false));
  }, [error])

  const handleSubmit = (event) => {
    event.preventDefault();
    setBtnState(false);
    props.callback(event, {"Phone": '+77'+phone.value.replace(/ /g, ''), "Theme": '['+i18n.language.toUpperCase()+'] ' + refTheme.current.value, "EmailFrom": refEmail.current.value, "Body": message.value});
  }

  return (
    <>
      <form className={cls(styles.form_container, styles.feedback)} onSubmit={handleSubmit}>
        <div className={cls(styles.controls_container, styles.feedback)}>
        {
          schema().fields.map((i, idx)=>{
            let control = null;
            switch(i.component.toLocaleLowerCase()) {
              case 'phone-field':
                control = <PhoneField 
                            {...phone} 
                            needError={false} 
                            goToNext={true} 
                            onInput={(e)=>{handleValidate(e, i.component.toLocaleLowerCase())}} 
                            onBlur={()=>{handleBlur(i.component.toLocaleLowerCase())}}
                            classError={cls(blur[i.component.toLocaleLowerCase()]&&error[i.component.toLocaleLowerCase()]&&controlStyles.error_block)}
                          />
              break;
              case 'theme-field':
                control = <div className={cls(controlStyles.phone_container, blur[i.component.toLocaleLowerCase()]&&error[i.component.toLocaleLowerCase()]&&controlStyles.error_block)}>
                            <input 
                              ref={refTheme} 
                              className={controlStyles.text} 
                              maxLength='100' 
                              onInput={(e)=>{handleValidate(e, i.component.toLocaleLowerCase())}}
                              onBlur={()=>{handleBlur(i.component.toLocaleLowerCase())}}
                            />
                          </div>
              break;
              case 'text-area-field':
                control = <TextAreaField 
                            {...message}  
                            maxLength='1000' 
                            onInput={(e)=>{handleValidate(e, i.component.toLocaleLowerCase())}}
                            onBlur={()=>{handleBlur(i.component.toLocaleLowerCase())}}
                            classError={cls(blur[i.component.toLocaleLowerCase()]&&error[i.component.toLocaleLowerCase()]&&controlStyles.error_block)}
                          />
              break;
              case 'email-field':
                control = <div className={cls(controlStyles.phone_container, blur[i.component.toLocaleLowerCase()]&&error['email-field']&&controlStyles.error_block)}>
                            <input 
                              ref={refEmail} 
                              className={controlStyles.text} 
                              maxLength='100' 
                              onInput={(e)=>{handleValidate(e, i.component.toLocaleLowerCase())}}
                              onBlur={()=>{handleBlur(i.component.toLocaleLowerCase())}}
                            />
                          </div>
              break;
              default:
                control = null;
            }
            return  <div key={`field-${idx}`}>
                      <Caption item={i} phone={phone}/>
                      {control}
                    </div>
          })
        } 
        <div className={cls(styles.btn, styles.mt30)}>
          <button type='submit' disabled={!btnState} className={styles.yellow}>{t(schema().submitLabel)}</button>
        </div> 
        </div>
        {
        props.isAuthorized && <div className={`${styles.btn} `}>
          <button type='button' className={styles.red} onClick={props.onDeleteAccount}>{t('delete-account')}</button>
        </div>
        }      
      </form>
    </>
  )
}
export default Form
