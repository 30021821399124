import React from "react";
import cls from "classnames";
import classes from "./view.module.scss";

const Warning = () => {
  return  <div className={cls(classes.warning)}>
            <div className="pt-2 pb-4 pb-sm-7 bg-inverse text-inverse">
              <div className="container-fluid">
                <div className="title-eyebrow">
                  <p>В <a href="https://www.mastercard.com/news/press/2022/march/mastercard-statement-on-suspension-of-russian-operations/" target="_blank" rel="noreferrer">заявлении от 5 марта 2022 года</a> компания Mastercard объявила о приостановке оказания услуг своей платежной системы в России.</p>
                  <p>В результате этого решения карты, выпущенные в России, больше не обслуживаются через платежную систему Mastercard.</p>
                  <p>Сервис Masterpass в России также недоступен. Mastercard прекратил обработку личной информации пользователей сервиса и уничтожил данные в соответствии с требованиями законодательства.</p>
                </div>              
              </div>
            </div>
          </div>
}

export default Warning;