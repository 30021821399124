import React, { useEffect, useState } from 'react'
import * as Queries from '../../queries/cards'  
import Loader from '../../components/elements/loader-vintage'
import { useRouter } from '../../components/utils/useRouter'
import CardsBlockView from '../../components/view/cards/block'
import { useTranslation } from 'react-i18next'
import { addPage } from '../../routes/prepare'
import ModalControl from '../../components/organizms/modal-control'
import ErrorField from '../../components/elements/form-controls/error-field'

const CardListPageContainer = () => {
  const { t } = useTranslation();
  const router = useRouter()
  const [ error, setError] = useState({show: false, text: ''})

  const [ response, setResponse ] = useState(false)
  const [ text, setText] = useState('')
  const [ wichModal, setWichModal] = useState(null)
  const [ modalData, setModalData] = useState(null)

  const { data: cards, refetch: getCardList, isLoading: cardsIsLoading } = Queries.useGet()
  
  const { mutate: deleteCard, isLoading: deleteCardIsLoading} = Queries.useDelete()
  const { mutate: changeNameCard, isLoading: changeNameCardIsLoading} = Queries.useChangeNameCard()

  const setDefaultResponse = (response = false, error = '') => {
    setResponse(response)
    setText(error);
  }

  const handleChangeNameCard = (event, data) => {
    event.preventDefault();
    if(!data.CardName.match(/[^-0-9a-zA-Zа-яА-ЯёЁ*€¢£¤¥ƒ₽$〒₴\\_\\+\\-\\(\\)+ ]/g)) {
      setDefaultResponse()
      return new Promise(()=>changeNameCard(data, {
        onSuccess: (res) => {
          if(res.Success) {  
            setDefaultResponse(false, '')
          } else {
            setDefaultResponse(true, t(`errors:${res.ErrCode}`))
          }   
        },
        onError: (error) => {
          setDefaultResponse(true, error.error)
        },
      }))
    } else {
      setDefaultResponse(true, t(`common:card-name-error`));
    }
  }

  const handleDeleteCard = (event, data) => {
    event.preventDefault();
    setDefaultResponse()
    return new Promise(()=>deleteCard(data, {
      onSuccess: (res) => {
        if(res.Success) {  
          setDefaultResponse(true, '');
          setWichModal(3)
        } else {
          setDefaultResponse(true, t(`errors:${res.ErrCode}`))
        }   
      },
      onError: (error) => {
        setDefaultResponse(true, error.error)
      },
    }))
  }
  
  const handleAddCard = () => {
    router.push( {
      pathname: addPage
    } )
  }

  const handleCardChangeModal = (data, wich) => {
    setResponse(true)
    setModalData(data)
    setWichModal(wich)
  }
  
  const handleCloseModal = () => {
    setResponse(false)
    setText('')
  }

  useEffect(()=>{
    if (router.query.Message || router.query.ErrCode) {
      if (router.query.Success === 'true') {
        //setError({show: true, text: t(`common:${router.query.Message}`)})
        setDefaultResponse(true, t(`common:${router.query.Message}`))
        setWichModal(8)
        setTimeout(()=>{
          getCardList()
          router.replace({pathname: ``})
        }, 2000)
      } else {
        //setError({show: true, text: t(`errors:${router.query.ErrCode}`)})
        setDefaultResponse(true, t(`mapping-errors:${router.query.ErrCode}`))
      }
      
      //setTimeout(()=>{
      //  setError({show: false, text: ''})
      //}, 5000)
    }
    // eslint-disable-next-line
  }, [router, t])

  useEffect(()=>{
    getCardList()
  }, [getCardList])

  return (
          <>
            <CardsBlockView 
              data={cards}
              onCardChange={handleCardChangeModal}
              onAddCard={handleAddCard}
            >
              <ErrorField text={error.text} error={error.show} variant='under_title'/>
            </CardsBlockView>
            <ModalControl
              response={response}
              wichModal={wichModal}
              text={text}
              data={modalData}
              onChangeNameCard={handleChangeNameCard}
              onDeleteCard={handleDeleteCard}
              onCloseModal={handleCloseModal}
            />
            <Loader enabled={cardsIsLoading||deleteCardIsLoading||changeNameCardIsLoading}/>
          </>
  )
}

export default CardListPageContainer
