import React from 'react';
import { IMaskMixin } from 'react-imask';
import styles from './controls.module.scss'

const MaskedInput = IMaskMixin(({inputRef, ...props}) => (
  <input
    {...props}
    ref={inputRef}
    className={styles.otp}
    autoFocus
  />
));

function OTPField({onChange, value}) {

  return (
    <>
    <div className={styles.phone_container}>
      <MaskedInput
        mask={Array(parseInt(process.env.REACT_APP_OTP_LENGTH)+1).join("0")}
        lazy={false} 
        onAccept={(value, mask)=>{
          onChange(value)
        }}
        value={value}
        onComplete={(value, mask) => {
          // tab focus on the next element
          var focussableElements = 'a:not([disabled]), button:not([disabled]), input:not([disabled]), input[type=password]:not([disabled]), input[type=text]:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';
          if (document.activeElement && document.activeElement.form) {
              var focussable = Array.prototype.filter.call(document.activeElement.form.querySelectorAll(focussableElements),
              function (element) {
                  return element.offsetWidth > 0 || element.offsetHeight > 0 || element === document.activeElement
              });
              var index = focussable.indexOf(document.activeElement);
              if(index > -1) {
                 var nextElement = focussable[index + 1] || focussable[0];
                 nextElement.focus();
              }                    
          }
        }}
        placeholderChar='_'
        /* placeholder={placeholder}
        required={isRequired} */
        type='tel'
      />
      
    </div>
    </>

  );
};

export default React.memo(OTPField)
