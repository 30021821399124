import styles from '../../form.module.scss'
import schema from '../../../../schemas/forms/auth/login'
import PhoneField from '../../../elements/form-controls/phone-field';
import { useState, useEffect, useRef } from 'react';
import { useGoogleReCaptcha }  from 'react-google-recaptcha-v3';
import ReCAPTCHA from "react-google-recaptcha";
import Loader from '../../../elements/loader-vintage'
import { useQueryClient } from 'react-query'
import { phoneMask } from '../../../utils/phoneMask'

const Form = ({t, callback, secondTry, ...props}) => {
  const [ btnState, setBtnState ] = useState(false);
  const queryCache = useQueryClient()
  let cachePhone = queryCache.getQueryData('Phone');
  const [ inputValue, setInputValue ] = useState(typeof queryCache !== 'undefined' && queryCache.getQueryData('Phone') ? (phoneMask(queryCache.getQueryData('Phone'))||''):'');
  const [ realInputValue, setRealInputValue ] = useState(typeof queryCache !== 'undefined' && queryCache.getQueryData('Phone') ? (queryCache.getQueryData('Phone')||''):'');
  const [ mask, setMask ] = useState(typeof queryCache !== 'undefined' && queryCache.getQueryData('Phone') && queryCache.getQueryData('Phone') !== '' ? '00 xxx xx 00':'00 000 00 00');
  const captchaRef = useRef(null)
  const [ inputError, setInputError ] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [captchaIsReady, setCaptchaIsReady] = useState(false);
  const [ captchaIsChecked, setCaptchaIsChecked ] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault()
    doSubmit()
  }
  
  const handleSubmit2 = (event) => {
    event.preventDefault()
    doSubmit2()
  }

  const doSubmit = async () => {
    const tok = await executeRecaptcha('homepage');
    setBtnState(false);
    callback({"CaptchaV3": tok, "Phone": realInputValue.replace(/ /g, '').length === 9 ? '77'+realInputValue.replace(/ /g, ''):'77'+inputValue.replace(/ /g, '') });
  }
  
  const doSubmit2 = async () => {
    const token = await captchaRef.current.getValue();
    setBtnState(false);
    captchaRef.current.reset();
    callback({"CaptchaV2": token, "Phone": realInputValue.replace(/ /g, '').length === 9 ? '77'+realInputValue.replace(/ /g, ''):'77'+inputValue.replace(/ /g, '') });
  }

  useEffect(() => {
    const handleReCaptchaVerify = async () => {
      if (typeof executeRecaptcha === 'function') { 
        await executeRecaptcha('homepage');
        setCaptchaIsReady(true)        
      } 
    };
    handleReCaptchaVerify();
  }, [executeRecaptcha]);

  const handleChange = (value) => {
    if (realInputValue.replace(/ /g,'').length === 9 && value.replace(/ /g, '') !== inputValue.replace(/ /g, '') ) {
      setInputValue('');
      setRealInputValue('');
      setMask('00 000 00 00')
    } else {
      setInputValue(value);
    }
    
  }

  useEffect(() => {
    let preparedValue = inputValue.replace(/ /g, '')
    console.log(preparedValue)
    console.log(JSON.stringify(inputError))
    if(preparedValue.length === 9 && !inputError && captchaIsChecked) {
      setBtnState(true)
    } else {
      setBtnState(false)
    } 
  }, [inputValue, inputError, captchaIsChecked])

  useEffect(()=>{
    if (queryCache.getQueryData('Phone') && queryCache.getQueryData('Phone').length === 9) {
      setInputValue(phoneMask(queryCache.getQueryData('Phone'))||'')
      setRealInputValue(queryCache.getQueryData('Phone')||'')
      setMask('00 xxx xx 00')
    }
    // eslint-disable-next-line
  }, [cachePhone])

/*   useEffect(() => {
    setBtnState(!secondTry)
  }, [secondTry]); */

  const handleCaptchaV2Success = () => {
    setCaptchaIsChecked(true)
  }

  return (
    <>
      <form className={styles.form_container} onSubmit={/* !secondTry ? handleSubmit: */handleSubmit2}>
      <div className={styles.caption}>{t(schema().title)}</div>
      <div className={styles.controls_container}>
      {
        schema().fields.map((i, idx)=>{
          let result = null;
          switch(i.component) {
            case 'phone-field':
              result = <PhoneField onChange={handleChange} mask={mask} value={inputValue} /* err={setInputError} */ key={`field-${idx}`}/>
            break;
            default:
              result = null;
          }
          return result;
        })
      } 
      </div>
      <div className={`${styles.btn} `}><button type='submit' disabled={!btnState}>{t(schema().submitLabel)}</button></div>    
      {
        /* secondTry &&  */<div className={styles.recaptcha}><ReCAPTCHA
          sitekey={'6Le5pLcUAAAAAN1bmVILj-Rci3tMJmgvkoU03K1q'} 
          ref={captchaRef}
          onChange={handleCaptchaV2Success}
          /></div>
      }        
      </form>
      <Loader enabled={!captchaIsReady} />
    </>
  )
}
export default Form
