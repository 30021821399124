import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`
    },
    fallbackLng: 'ru',
    ns: ["common", "errors", "mapping-errors"],
    defaultNS: "common",
    debug: false,
    keySeparator: true,
    react: {
      useSuspense: true
    }
  });


export default i18n;
