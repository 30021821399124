const schema = () => { return {
  title: 'feedback',
  
  fields: [{ 
    component: 'phone-field',
    name: 'Phone',
    autoComplete: 'tel',
    caption: 'feedback-phone',
    autoFocus: true,
    className: 'left'
  },{
    component: 'email-field',
    type: 'email',
    name: 'Email',
    caption: 'feddback-email',
    className: 'left'
  },{
    component: 'theme-field',
    type: 'text',
    name: 'Theme',
    caption: 'feedback-theme',
    className: 'left'
  },{
    component: 'text-area-field',
    name: 'Message', 
    type: 'text',
    caption: 'feedback-message',
    className: 'left'
  }],
  submitLabel: 'send'
}}




export default schema