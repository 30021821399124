import axios from "axios";

export const getBaseURLApi = () =>
  process.env.NODE_ENV === "production" ? "/mpapi" : "http://localhost/mpapi/";
export const getBaseURLApp = () =>
  process.env.NODE_ENV === "production" ? "/" : "/";

const createClient = (baseUrl) => {
  const cli = axios.create({
    baseURL: baseUrl,
    timeout: 30000,
    headers: {
      "content-type": "multipart/form-data;charset=UTF-8",
    },
    withCredentials: true,
  });

  cli.__base = baseUrl;
  return cli;
};

const mpapi = createClient(getBaseURLApi());
const mpapp = createClient(getBaseURLApp());

const _axiosGetApi = mpapi.get;

mpapi.get = function (url, data = null) {
  const configuration = data
    ? {
        params: { Data: JSON.stringify(data) },
      }
    : null;

  return _axiosGetApi(url, configuration);
};

mpapi.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    if (typeof error.response == "undefined" || 403 === error.response.status) {
      //window.location = "/";
    } else {
      return Promise.reject(error.response);
    }
  }
);

const _axiosGetApp = mpapp.get;

mpapi.get = function (url, data = null) {
  const configuration = data
    ? {
        params: { Data: JSON.stringify(data) },
      }
    : null;

  return _axiosGetApp(url, configuration);
};

mpapp.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error) {
    if (typeof error.response == "undefined" || 403 === error.response.status) {
      window.location = "/";
    } else {
      return Promise.reject(error.response);
    }
  }
);

export { mpapi, mpapp };
