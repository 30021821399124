import React, { useState, useCallback } from 'react'
import * as Queries from '../../queries/cards'
import ModalControl from '../../components/organizms/modal-control'
import ErrorField from '../../components/elements/form-controls/error-field'
import FeedbackForm from '../../components/forms/feedback'
import FeedbackBlockView from '../../components/view/feedback'
import { useTranslation } from 'react-i18next'

const FeedbackPageContainer = ({...props}) => {
  const { t } = useTranslation();
  const [ response, setResponse ] = useState(false)
  const [ text, setText] = useState('')
  const [ wichModal, setWichModal] = useState(null)
  const [ modalData, setModalData] = useState(null)

  const [ error, setError] = useState({show: false, text: ''});
  const { mutate: deleteAccount, isLoading: deleteAccountIsLoading} = Queries.useDeleteAccount()
  const { mutate: sendFeedback, isLoading: sendFeedbackIsLoading} = Queries.useSendFeedback()
  
  const processError = useCallback((errCode) => {
    setError({show: true, text: t(`errors:${errCode}`)})
  },[t])  
  
  const setDefaultResponse = (response = false, error = '') => {
    setResponse(response)
    setText(error);
  }

  const doSendFeedback = useCallback((event, data) => {
    event.preventDefault();
    setError({show: false, text: ''})
    setDefaultResponse()
    return new Promise(()=>sendFeedback(data, {
      onSuccess: (res) => {
        if(res.Success) {
          setDefaultResponse(true, '');
          setWichModal(6)
        } else {
          processError(res.ErrCode)
        }   
      },
      onError: (error) => {
        processError(error)
      },
    }))
    // eslint-disable-next-line
  },[])

  const doDeleteAccount = useCallback((event, data) => {
    event.preventDefault();
    setError({show: false, text: ''})
    setDefaultResponse()
    return new Promise(()=>deleteAccount(data, {
      onSuccess: (res) => {
        if(res.Success) {
          debugger;
          setDefaultResponse(true, '');
          setWichModal(5)
        } else {
          setDefaultResponse(true, t(`errors:${res.ErrCode}`))
        }   
      },
      onError: (error) => {
        processError(error)
      },
    }))
    // eslint-disable-next-line
  },[])

  const handleDeleteAccountModal = () => {
    setResponse(true)
    setModalData({})
    setWichModal(4)
  }

  const handleCloseModal = () => {
    setResponse(false)
    setText('')
  }

  return (
          <>
            <FeedbackBlockView 
              loading={deleteAccountIsLoading||sendFeedbackIsLoading} 
              isAuthorized={props.isAuthorized} 
              form={FeedbackForm} 
              callback={doSendFeedback}
              onDeleteAccount={handleDeleteAccountModal}
              response={response}
            />
            <ModalControl
              response={response}
              wichModal={wichModal}
              text={text}
              data={modalData}
              onDeleteAccount={doDeleteAccount}
              onCloseModal={handleCloseModal}
            />
            <ErrorField text={error.text} error={error.show}/>
          </>
  )
}

export default FeedbackPageContainer
