import React from 'react'
import cls from 'classnames'
import TitleView from '../title-view.js'
import Styles from '../view.module.scss' 
import FormStyles from '../../forms/form.module.scss' 
import { useTranslation } from 'react-i18next'

const OTPBoxView = ({callback, phone, smsSendCallback, error, setError, ...props}) => {
    const { t, i18n } = useTranslation()
  return  (
    <>
    <TitleView t={t}>main-entrance</TitleView>
    <props.form t={t} callback={callback} phone={phone} error={error} setError={setError} smsSendCallback={smsSendCallback}/>
    <div className={cls(FormStyles.form_container, Styles.offerta)}>
    {
    i18n.resolvedLanguage === 'ru' ? 
    <>Я прочитал и соглашаюсь с <a href='/terms.html' target='_blank'>Условиями использования сервиса Masterpass</a> и <a href='/privacy.html' target='_blank'>Соглашением о защите персональных данных,</a> а также предоставляю свое свободное согласие для обработки моих персональных данных компанией Mastercard, как указано в упомянутой документации</> : 
    <>Мен оқып шықтым және <a href='/terms.html' target='_blank'>Masterpass сервисін қолдану Шарттарымен</a> және <a href='/privacy.html' target='_blank'>дербес мәліметтерді қорғау Келісімімен</a> келісемін, сонымен бірге, айтылған құжаттамада көрсетілген,  MasterCard компаниясымен менің дербес деректерімді өңдеу үшін өз ерікті келісімді ұсынамын.</>
    }
    </div>
    </>
  )      
}
 
export default OTPBoxView