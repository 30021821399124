import React from 'react'
import { NavLink as Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styles from '../../../styles/header.module.scss'
import { listPage, loginPage, logoutPage, termsPage, privacyPage, feedbackPage } from '../../../routes/prepare'
import cls from 'classnames'

const BurgerMenu = ({visibility, isAuthorized, page, onMenuItemClick}) => {

  const { t } = useTranslation()

  return (
      <div className={cls(styles.burger_menu_cover, visibility && styles.open)}>
        <ul className={`${styles.navbar_nav} d-flex flex-direction-column`}>
          {
          isAuthorized && <li className={styles.nav_item}>
            <Link to={listPage} onClick={onMenuItemClick} className={styles.nav_link} activeClassName={styles.active}>{t('common:my-cards')}</Link>
          </li>                               
          }
          <li className={styles.nav_item}>
            <a className={`${styles.nav_link} text-initial  btn`} href="/locales/faq.pdf" target="_blank" rel="noreferrer">{t('common:faq')}</a>
          </li>
          {
          !isAuthorized && page !== 1 && <li className={styles.nav_item}>
            
              <Link to={loginPage} onClick={onMenuItemClick} exact className={styles.nav_link} activeClassName={styles.active}>{t('common:main-entrance')}</Link>
            
          </li>                              
          }
          <li className={styles.nav_item}>
            <Link to={termsPage} onClick={onMenuItemClick} className={styles.nav_link} activeClassName={styles.active}>{t('common:terms')}</Link>
          </li>
          <li className={styles.nav_item}>
            <Link to={privacyPage} onClick={onMenuItemClick} className={styles.nav_link} activeClassName={styles.active}>{t('common:privacy')}</Link>
          </li>
          <li className={styles.nav_item}>
            <Link to={feedbackPage} onClick={onMenuItemClick} className={styles.nav_link} activeClassName={styles.active}>{t('common:feedback')}</Link>
          </li>
          {
          isAuthorized && <li className={styles.nav_item}>
            <Link to={logoutPage} onClick={onMenuItemClick} className={styles.nav_link} activeClassName={styles.active}>{t('common:logout')}</Link>
          </li>                              
          }

        </ul>
      </div>
  )
}
 
export default React.memo(BurgerMenu)
