import { useEffect, useRef } from 'react';

export default function Process3DS({res}) {
  const formRef = useRef(null)
  useEffect(()=>{
    if (formRef && formRef.current) {
      formRef.current.submit();
    }
  }, [])

  return (
    <form name='form' ref={formRef} action={res.ACSUrl} method='post'>
      <input type='hidden' name='TermUrl' value={res.TermUrl}/>
      <input type='hidden' name='MD' value={res.MD}/>
      <input type='hidden' name='PaReq' value={res.PaReq}/>
    </form>
  )
}