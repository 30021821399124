import React from 'react';
import { useTranslation } from 'react-i18next'
import styles from './lang-switcher.module.scss'
import GlobusImg from '../../../assets/img/globus.png'
import Arrow from '../../../assets/img/arrow_down.png'

const LangSwitcher = () => {

  const { t, i18n } = useTranslation()

  return (
    <> 
      <div className={styles.main}
        onClick={() => {i18n.changeLanguage(i18n.resolvedLanguage === 'ru' ? 'kz' : 'ru');}}
      >
        <span className={styles.globus}><img src={GlobusImg} alt='' /></span>
        <span>{i18n.resolvedLanguage === 'ru' ? t('ru') : t('kz') }</span>
        <span><img src={Arrow} alt='' /></span>
      </div>
    </>
  )
}
 
export default React.memo(LangSwitcher);
