import React, { useCallback/* , useMemo  */} from "react";
import { useState, useRef, useEffect } from "react";
import { IMaskInput } from "react-imask";
/* import ErrorField from "./error-field"; */
import styles from "./controls.module.scss";
import cls from "classnames";
/* import { useAvailablePhoneNumberPatterns } from "../../../queries/validation"; */

function PhoneField({
  onChange,
  value,
  err,
  needError = true,
  goToNext = false,
  classError = "",
  mask = "00 000 00 00",
  disabled: disabledProp,
  ...props
}) {
  const [error, setError] = useState();
  const inputElement = useRef(null);

/*   const { isLoading: isPatternLoading, data: availablePatterns } =
    useAvailablePhoneNumberPatterns(); */

  useEffect(() => {
    if (inputElement.current.element) {
      inputElement.current.element.focus();
    }
  }, [inputElement]);

/*   const validate = useMemo(
    () => (value) => {

      if (Array.isArray(availablePatterns) && availablePatterns.length > 0) {
        return (
          value !== "" &&
          availablePatterns.every((template) => {
            const pattern = new RegExp(`^(${(template.Prefix).toString().slice(1)})`);
            return !pattern.test(value);
          })
        );
      } else {
        return value === "";
      }
    },
    [availablePatterns]
  ); */

  const onAccept = useCallback(
    (value, mask) => {
      onChange(value);

      if (needError) {
        setError(/* validate */(value));
      }

      if (err) {
        err(/* validate */(value));
      }
    },
    [onChange, needError, err, setError, /* validate */]
  );

  const inputDisabled = disabledProp /*  || isPatternLoading */;

/*   const availablePhonePatternsText = useMemo(() => {
    if (Array.isArray(availablePatterns) && availablePatterns.length > 0) {
      return availablePatterns.map((pattern) => `+${pattern.Prefix}`).join(", ");
    } else {
      return "+77";
    }
  }, [availablePatterns]); */

  return (
    <>
      <div className={cls(styles.phone_container, classError)}>
        <div className={styles.prefix}>+77</div>
        <IMaskInput
          mask={mask}
          lazy={true}
          onAccept={onAccept}
          onComplete={(value, mask) => {
            // tab focus on the next element
            if (goToNext) {
              var focussableElements =
                'a:not([disabled]), button:not([disabled]), input:not([disabled]), input[type=password]:not([disabled]), input[type=text]:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';
              if (document.activeElement && document.activeElement.form) {
                var focussable = Array.prototype.filter.call(
                  document.activeElement.form.querySelectorAll(
                    focussableElements
                  ),
                  function (element) {
                    return (
                      element.offsetWidth > 0 ||
                      element.offsetHeight > 0 ||
                      element === document.activeElement
                    );
                  }
                );
                var index = focussable.indexOf(document.activeElement);
                if (index > -1) {
                  var nextElement = focussable[index + 1] || focussable[0];
                  nextElement.focus();
                }
              }
            }
          }}
          value={value}
          placeholderChar=" "
          ref={inputElement}
          className={styles.phone}
          autoComplete="tel"
          type="tel"
          disabled={inputDisabled}
          {...props}
        />
      </div>
{/*       <ErrorField
        text={`Сервис доступен только для номеров<br> Республики Казахстан (${availablePhonePatternsText}).`}
        error={error}
      /> */}
    </>
  );
}

export default PhoneField;
