const schema = () => { return {
    title: 'otp',
    
    fields: [ {
      component: 'otp-field',
      type: 'password',
      name: 'OTP',
      autoFocus: true
    }],
    submitLabel: 'enter'
  }}

export default schema