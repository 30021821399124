import { useQuery, useMutation, useQueryClient } from 'react-query'
import * as DBApi from './db-api'
import { useLocation, useHistory } from 'react-router-dom'
import { checkPage, loginPage } from '../routes/prepare'


export function useAuthorizeUser() {
  const queryCache = useQueryClient()
  return useMutation(DBApi.auth.authorizeUser, {
    onSuccess: (data) => {
      queryCache.invalidateQueries()
      queryCache.setQueryData('Authorized', data.Success)
      if(data.Phone && typeof data.Phone != 'undefined') {
				queryCache.setQueryData('Phone', data.Phone.slice(2))
      }
    },
    onError: () => {
      console.log('AUTH ERROR main')
      queryCache.invalidateQueries()
      queryCache.setQueryData('Authorized', false)
    }
  });
}

export function useSendOTP() {
  const queryCache = useQueryClient()
  return useMutation(DBApi.auth.sendOTP, {
    onSuccess: (data) => {
      if(data.Success) {

      } else {

      }
    },
    onError: () => {
      console.log('send otp ERROR main')
      queryCache.invalidateQueries()
      queryCache.setQueryData('Authorized', false)
    },
    useErrorBoundaries: true
  });
}

export function useCheckOTP() {
  const queryCache = useQueryClient()
  return useMutation(DBApi.auth.checkOTP, {
    onSuccess: (data) => {
      queryCache.invalidateQueries()
      queryCache.setQueryData('Authorized', data.Success)
    },
    onError: () => {
      console.log('check otp ERROR main')
      queryCache.invalidateQueries()
      queryCache.setQueryData('Authorized', false)
    },
    useErrorBoundaries: true
  });
}

export function useCheckSecondOTP() {
  const queryCache = useQueryClient()
  return useMutation(DBApi.auth.checkOTP2, {
    onSuccess: (data) => {
      queryCache.invalidateQueries()
      queryCache.setQueryData('Authorized', data.Success)
    },
    onError: () => {
      console.log('check otp ERROR main')
      queryCache.invalidateQueries()
      queryCache.setQueryData('Authorized', false)
    },
    useErrorBoundaries: true
  });
}

export function useLoginUser() {
  const queryCache = useQueryClient()
  return useMutation(DBApi.auth.loginUser, {
    onSuccess: (data) => {
      if(data.Success) {
        
      } else {
        
      }
    },
    onError: () => {
      console.log('LOGIN ERROR main')
      queryCache.setQueryData('Authorized', false)
    },
    useErrorBoundaries: true
  });
}

export function useLogoutUser() {
  const queryCache = useQueryClient();
  let history = useHistory();
  return useQuery("Logout", DBApi.auth.logoutUser, {
    onSuccess: (data) => {
      queryCache.setQueryData('Authorized', false) 
      history.push({
        pathname: loginPage,
      })  
    },
    onError: () => {

    },
  });
}

export function useCheckSession(interval) {
  let location = useLocation();
  let history = useHistory();

  return useQuery("CheckSession", DBApi.auth.authorizeUser/* DBApi.auth.checkSession */, {
    refetchInterval: interval,
    enabled: !!interval,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    onSuccess: (data) => {
      if (!data.Success) {
         if (!!interval) {
          history.push({
            pathname: location.pathname + '/' + checkPage,
            state: {
              background: location
            }
          })          
        }
      }
      
    }
  });
}

export function useGetServerState () {
  return useMutation(DBApi.auth.getServerState, {
    onSuccess: (data) => {
      if(data.Success) {
        
      } else {
        
      }
    },
    onError: () => {
      console.log('ServerState ERROR')
    },
  })
}

export function useGetHTML (pageName, language) {
  return useQuery(pageName, ()=>DBApi.auth.getHTML(pageName, language), {
    enabled: false,
    retry: false,
    onSuccess: (data) => {
      if(data.Success) {
        
      } else {
        
      }
    },
    onError: () => {
      console.log('ServerState ERROR')
    },
  })
}


