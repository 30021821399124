const schema = () => { return {
    title: 'login',
    fields: [{
      component: 'phone-field',
      name: 'Phone',
      autoComplete: 'tel',
      autoFocus: true,
    }],
    submitLabel: 'getPasswordBySMS'
  }}

export default schema