import React from 'react'
import { useTranslation } from 'react-i18next'
import LangSwitcher from '../../elements/lang-switcher'
import CopyrightString from '../../elements/copyright-string'
import { Link } from 'react-router-dom'
import styles from '../../../styles/footer.module.scss'
import { termsPage, privacyPage, feedbackPage } from '../../../routes/prepare'

const Footer = ({isAuthorized}) => {
  const { t } = useTranslation('common')
  return (
    <footer className={styles.footer}>
      <div className="container-fluid">
        <div className="d-flex flex-direction-row flex-nowrap flex-align-items-center">
          <div className="hidden-sm-down">
            <Link to={termsPage}>{t('common:terms')}</Link>
            <Link to={privacyPage}>{t('common:privacy')}</Link>
            <Link to={feedbackPage}>{t('common:feedback')}</Link>
          </div>
          <div className={styles.lang}>
            <LangSwitcher/>
          </div>
        </div>
        <div className={styles.copyright}>
          <CopyrightString/>
        </div>
      </div>
    </footer>
  )
}

export default React.memo(Footer)
