import React, { useCallback, useEffect, useState } from 'react'
import * as Queries from '../../queries/authorization'  
import LoginBoxView from '../../components/view/login-box'
import OTPBoxView from '../../components/view/otp-box'
import OTPForm from '../../components/forms/auth/otp' 
import SecondOTPForm from '../../components/forms/auth/second-otp' 
import { useHistory } from 'react-router-dom'
import LoginForm from '../../components/forms/auth/login'
import ErrorField from '../../components/elements/form-controls/error-field'
import { useTranslation } from 'react-i18next'
import Loader from '../../components/elements/loader-vintage'
import pluralize from 'plural-ru'
import ModalControl from '../../components/organizms/modal-control'

const LoginPageContainer = ({...props}) => {
  const { t } = useTranslation();
  const { mutate: login, isLoading: loginIsLoading } = Queries.useSendOTP();
  const [ secondTry, setSecondTry ] = useState(false);
  const { mutate: checkOTP, isLoading: checkOTPIsLoading } = Queries.useCheckOTP();
  const { mutate: checkSecondOTP, isLoading: checkSecondOTPIsLoading } = Queries.useCheckSecondOTP();
  const { data: serverState, mutate: refetchServerState } = Queries.useGetServerState();
  const [ isOTPWaiting, setOTPWaiting ] = useState(false);
  const [ isSecondOTPWaiting, setSecondOTPWaiting ] = useState(false);
  const [ error, setError] = useState({show: false, text: ''});
  const [ phone, setPhone ] = useState();
  const [ banned, setBanned ] = useState(false);
  const [ otpError, setOTPError ] = useState(false);

  const [ response, setResponse ] = useState(false)
  const [ text, setText] = useState('')

  const [ firstOTPCount, setFirstOTPCount ] = useState(0)

  const router = useHistory();

  const savePhone = (phone) => {
    setPhone(phone)
  }

  useEffect(()=>{
    if (otpError === false) {
      setTimeout(()=>{
        setError({show: false, text: ''})
      }, 5000)
    }
  }, [otpError])

  const processError = useCallback((errCode, res) => {
    setError({show: true, text: (t(`errors:${errCode}`)  + (errCode === 1 && res && res.Days ? ` ${t('on')} ${pluralize(res.Days, '%d день', '%d дня', '%d дней')}` : '')) })
  },[t])

  const doLogin = useCallback((data) => {
    setError({show: false, text: ''})
    return new Promise(()=>login(data, {
      onSuccess: (res) => {
        if(res.Success) {
          savePhone(data.Phone)
          setOTPWaiting(true);
          
          if (res.Status === "FirstMethodOTP") {
            
          }   
          if (res.Status === "SecondMethodOTP") {
            setSecondOTPWaiting(true);
          }       
        } else {
          //processError(res.ErrCode, res)
          
          if (res.ErrCode === 1) {
            setFirstOTPCount((firstOTPCount+3))
            setDefaultResponse(true, (t(`errors:${res.ErrCode}`)  + (res.ErrCode === 1 && res && res.Days ? ` ${t('on')} ${pluralize(res.Days, '%d день', '%d дня', '%d дней')}` : '')))
          } else if(res.ErrCode === 47) {
            setSecondTry(true)
          } else {
            setDefaultResponse(true, (t(`errors:${res.ErrCode}`)  + (res.ErrCode === 1 && res && res.Days ? ` ${t('on')} ${pluralize(res.Days, '%d день', '%d дня', '%d дней')}` : '')))
          }
        }   
      },
      onError: (error) => {
        processError(error)
      },
    }))
    // eslint-disable-next-line
  },[login, processError, t])

  useEffect(()=>{
    if(!sessionStorage.getItem('serverstate')) {
      refetchServerState(null,{
        onSuccess: (res) => {
          sessionStorage.setItem('serverstate', true)
        }
      });      
    }

    return ()=> {
      
    }
    // eslint-disable-next-line
  },[])

  const redirectAfterSuccessLogin = useCallback((res) => {
    /* if (res.IsActive) { */
      router.push(`${process.env.PUBLIC_URL}/listcard.html`);
/*     } else {
      if (res.IsNew) {
        router.push( {
          pathname: `${process.env.PUBLIC_URL}/addcard.html`,
          query: { cardscount: 0, isNew: true },
        } )
      } else {
        router.push( {
          pathname: `${process.env.PUBLIC_URL}/addcard.html`,
          query: { cardscount: 0 },
        } )
      }
    }   */   
  }, [router])

  useEffect(()=>{
    if (firstOTPCount >= 3) {
      setFirstOTPCount(0);
      setOTPWaiting(false);
      setSecondOTPWaiting(false);
    } 
  }, [firstOTPCount])

  const doCheckOTP = useCallback((data) => {
    setError({show: false, text: ''})
    return new Promise(()=>checkOTP(data, {
      onSuccess: (res) => {
        if(res.Success) {  
          redirectAfterSuccessLogin(res);
        } else {
          if (res.ErrCode === 39 || res.ErrCode === 4) {
            setOTPWaiting(false);
            setSecondOTPWaiting(false);
            setBanned(true);
            setDefaultResponse(true, (t(`errors:${res.ErrCode}`)  + (res.ErrCode === 1 && res.Days ? ` ${t('on')} ${pluralize(res.Days, '%d день', '%d дня', '%d дней')}` : '')))
            setOTPError(true);
            setFirstOTPCount((firstOTPCount+3))
          } else {
            setDefaultResponse(true, (t(`errors:${res.ErrCode}`)  + (res.ErrCode === 1 && res.Days ? ` ${t('on')} ${pluralize(res.Days, '%d день', '%d дня', '%d дней')}` : '')))
            setOTPError(true);
            if (res.ErrCode === 1 && res.Days) {
              setFirstOTPCount((firstOTPCount+3))
            } else {
              
            }
          }
        }   
      },
      onError: (error) => {
        processError(error)      
      },
    })) 
  },[checkOTP, redirectAfterSuccessLogin, processError, t, firstOTPCount])

  const doCheckSecondOTP = useCallback((data) => {
    setError({show: false, text: ''})
    return new Promise(()=>checkSecondOTP(data, {
      onSuccess: (res) => {
        if(res.Success) {
          redirectAfterSuccessLogin(res);
        } else {
          if (res.ErrCode === 39 || res.ErrCode === 4) {
            setOTPWaiting(false);
            setSecondOTPWaiting(false);
            setBanned(true);
          } else {
            //processError(res.ErrCode, res)
            setDefaultResponse(true, (t(`errors:${res.ErrCode}`)  + (res.ErrCode === 1 && res.Days ? ` ${t('on')} ${pluralize(res.Days, '%d день', '%d дня', '%d дней')}` : '')))
            setOTPError(true)   
            if (res.ErrCode === 1 && res.Days) {
              setFirstOTPCount((firstOTPCount+3))
            } else {
              setFirstOTPCount((firstOTPCount+1))
            }  
          }
        }   
      },
      onError: (error) => {
        processError(error)
      },
    })) 
    // eslint-disable-next-line
  }, [checkSecondOTP, redirectAfterSuccessLogin, processError, t])

  const setDefaultResponse = (response = false, error = '') => {
    setResponse(response)
    setText(error);
  }

  const handleCloseModal = () => {
    setResponse(false)
    setText('')
  }

  return (
          <>
            <> 
               {
                isOTPWaiting === false ? 
                  <LoginBoxView 
                    loading={loginIsLoading} 
                    isAuthorized={props.isAuthorized} 
                    form={LoginForm} 
                    callback={doLogin}
                    serverState={serverState}
                    banned={banned}
                    secondTry={secondTry}
                  />
                  :
                  (
                    isSecondOTPWaiting === false ?
                      <OTPBoxView 
                        loading={checkOTPIsLoading} 
                        isAuthorized={props.isAuthorized} 
                        form={OTPForm} 
                        callback={doCheckOTP}
                        smsSendCallback={doLogin}
                        phone={phone}
                        error={otpError}
                        setError={setOTPError}
                      />
                      :
                      <OTPBoxView 
                        loading={checkSecondOTPIsLoading} 
                        isAuthorized={props.isAuthorized} 
                        form={SecondOTPForm} 
                        callback={doCheckSecondOTP} 
                        smsSendCallback={doLogin}
                        phone={phone}
                        error={otpError}
                        setError={setOTPError}                     
                      />
                  )
   
              }       
              <ModalControl
                response={response}
                wichModal={-1}
                text={text}
                data={''}
                onCloseModal={handleCloseModal}
              />
              
              <ErrorField text={error.text} error={error.show}/>
              <Loader enabled={loginIsLoading||checkOTPIsLoading||checkSecondOTPIsLoading}/>
            </>
          </>
  )
}

export default LoginPageContainer
