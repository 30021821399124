import React, { Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import { QueryErrorResetBoundary } from 'react-query'
import { ErrorBoundary } from 'react-error-boundary'

import PublicRoute from './public-route'
import PrivateRoute from './private-route'

import LoadingSpiner from '../components/elements/loader-vintage'
import NotFoundPage from '../components/organizms/404-page'

import LoginPage from '../containers/login-page'
import LogoutPage from '../containers/logout-page'
import CardListPage from '../containers/card-list-page'
import CardAddPage from '../containers/card-add-page'
import TextPage from '../containers/text-page'
import FeedbackPage from '../containers/feedback-page'

const Routes = () => {
  return (
      <QueryErrorResetBoundary>
        {({ reset }) => (
          <ErrorBoundary
            onReset={reset}
            fallbackRender={({ resetErrorBoundary }) => <div>error</div>}
          >
            <Router>
              <Suspense fallback={<LoadingSpiner enabled={true}/>}>
                <Switch>
                  <PublicRoute path={`${process.env.PUBLIC_URL}(/?)`} restricted={true} exact component={LoginPage}/>
                  <PublicRoute path={`${process.env.PUBLIC_URL}/logout`} restricted={false} exact component={LogoutPage}/>
                  <PublicRoute path={`${process.env.PUBLIC_URL}/privacy.html`} restricted={false} pageName="privacy" exact component={TextPage}/>
                  <PublicRoute path={`${process.env.PUBLIC_URL}/terms.html`} restricted={false} pageName="terms" exact component={TextPage}/>
                  <PrivateRoute path={`${process.env.PUBLIC_URL}/listcard.html`} exact component={CardListPage}/>
                  <PrivateRoute path={`${process.env.PUBLIC_URL}/addcard.html`} exact component={CardAddPage}/>
                  <PublicRoute path={`${process.env.PUBLIC_URL}/feedback.html`} fullHeight={true} restricted={false} exact component={FeedbackPage}/>
                  <Route component={NotFoundPage}/>
                </Switch>
              </Suspense>
            </Router>
          </ErrorBoundary>
        )}
      </QueryErrorResetBoundary>    
  )
}

export default Routes
