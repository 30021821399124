import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuthorization } from '../components/utils/withAuthorization'
import { listPage } from './prepare'
import Layout from '../components/layouts'

const PublicRoute = ({component: Component, restricted, fullHeight=false, ...rest}) => {
    const isAuthorized = useAuthorization()
    return (
        <Route {...rest} render={props => (
            isAuthorized && restricted ?
                <Redirect to={listPage} />
            : (isAuthorized === false || (!restricted && isAuthorized)) && <Layout isAuthorized={isAuthorized} restricted={restricted} fullHeight={fullHeight}><Component isAuthorized={isAuthorized} {...props} {...rest}/></Layout>
        )} />
    );
};

export default PublicRoute;
