import React from 'react'
import { CSSTransition } from 'react-transition-group';
import localStyles from './loader-vintage.module.scss'
import './fade.css'

const duration = 500;

const Loader = ({ enabled, className }) => {
  return (
  <CSSTransition in={enabled} timeout={duration} unmountOnExit={true} mountOnEnter={true} appear={false} enter={false}>
      <div className={`${className || ''} ${localStyles.loader_overlay}`}>
        <div className={localStyles.spinner}>
          <div className={localStyles.spinner_item}></div>
          <div className={localStyles.spinner_item}></div>
          <div className={localStyles.spinner_item}></div>
        </div>
      </div>
    </CSSTransition>
  )
}

export default Loader
