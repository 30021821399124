import React, { useState } from 'react'
import { NavLink as Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import styles from '../../../styles/header.module.scss'
import BurgerMenu from '../burger-menu'
import logo from '../../../assets/img/mp_hrz_pos.png'
import { listPage, loginPage, logoutPage } from '../../../routes/prepare'
import cls from 'classnames'

const Header = ({isAuthorized, page}) => {

  const { t } = useTranslation('common')
  const [ opened, setOpened ] = useState(false)
  
  const handleBurgerMenuItemClick = () => {
    setOpened(false)
  }

  return (
  <>
    <nav className={styles.navbar} role="navigation">
        <div className="container-fluid">
            <div className="d-flex flex-direction-row flex-nowrap flex-justify-content-between flex-align-items-center">
                <span className={styles.navbar_logo}>
                    <img alt="Masterpass" src={logo} width={200} />
                </span>
                <div onClick={()=>{setOpened(!opened)}} className={cls(styles.navbar_toggler, opened && styles.open,"hidden-md-up", "flex-order-last", "js-subnav-hook")}>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <div className={`${styles.navbar_items} d-flex flex-justify-content-end`}>
                    <div className="hidden-sm-down">
                        <ul className={`${styles.navbar_nav} d-flex flex-direction-row`}>
                            {
                            isAuthorized && <li className={styles.nav_item}>
                              <Link to={listPage} className={styles.nav_link} activeClassName={styles.active}>{t('common:my-cards')}</Link>
                            </li>                               
                            }
                            <li className={styles.nav_item}>
                              <a className={`${styles.nav_link} text-initial  btn`} href="/locales/faq.pdf" target="_blank" rel="noreferrer">{t('common:faq')}</a>
                            </li>
                            {
                            !isAuthorized && page !== 1 && <li className={styles.nav_item}>
                              
                                <Link to={loginPage} exact className={styles.nav_link} activeClassName={styles.active}>{t('common:main-entrance')}</Link>
                              
                            </li>                              
                            }
                            {
                            isAuthorized && <li className={styles.nav_item}>
                              <Link to={logoutPage} exact className={styles.nav_link} activeClassName={styles.active}>{t('common:logout')}</Link>
                            </li>                              
                            }

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </nav>
    <BurgerMenu visibility={opened} isAuthorized={isAuthorized} page={page} onMenuItemClick={handleBurgerMenuItemClick}/>
  </>
  )
}
 
export default React.memo(Header)
