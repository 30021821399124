import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import cls from 'classnames'

import modalStyles from '../../organizms/modal-control/modal.module.scss'
import TitleView from '../title-view.js'
import Warning from '../warning.js'

Modal.setAppElement('body')

const LoginBoxView = ({callback, serverState, banned, secondTry, ...props}) => {
  const { t } = useTranslation();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState();

  const openModal = () => {
    setIsOpen(true);
  }

  const closeModal = () => {
    setIsOpen(false);
  }

  useEffect(()=>{
    if (serverState) {
      if (serverState.Success && serverState.IsTest) {
        setModalContent(
        <div className={modalStyles.server_state_container}>        
          <div className={modalStyles.text}>{t('common:server-state-test')}</div>
          <div className={modalStyles.center}>
            <button className={cls(modalStyles.button, modalStyles.button_cancel)} onClick={closeModal}>OK</button>
          </div>
        </div>          
        )
        openModal()
      }
    }
  }, [serverState, t])

  useEffect(()=>{
    if (banned) {
      setModalContent(
      <div className={modalStyles.server_state_container}>        
        <div className={modalStyles.text}>{t('common:otp-second-ban')}</div>
        <div className={modalStyles.center}>
          <button className={cls(modalStyles.button, modalStyles.button_cancel)} onClick={closeModal}>OK</button>
        </div>
      </div>          
      )
      openModal()
    }
  }, [banned, t])

  return  (
    <>
    <Warning />
    <TitleView t={t}>main-entrance</TitleView>
    <props.form t={t} secondTry={secondTry} callback={callback}/>    
    <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        overlayClassName={modalStyles.overlay}
        className={modalStyles.content}
        contentLabel=""
      >
        <button className={modalStyles.close} onClick={closeModal}/>
        {
          modalContent
        }
      </Modal>
    </>
  )
}
 
export default LoginBoxView
