import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next'
import Modal from 'react-modal'
import cls from 'classnames'
import modalStyles from './modal.module.scss' 

Modal.setAppElement('body')

const ModalControl = ({ response, wichModal, text, data, nextModal, onChangeNameCard, onDeleteCard, onCloseModal, onDeleteAccount }) => {

  const { t } = useTranslation('common')

  const deleteCardText = t('delete-card-tip')
  const deleteCardCaption = t('delete-card')
  const deleteCardResponseCaption = t('card')
  const deleteCardResponseText = t('card-deleted')
  const editCardNameText = t('tip-text')
  const editCardNameCaption = t('rename-card')

  const [modalMessage, setModalMessage] = useState('')
  const [modalContent, setModalContent] = useState()
  const inputEl = useRef(null)
  const error = useRef(null)

  useEffect(() => {
    if(error.current) {
      error.current.innerHTML = modalMessage
    }
  }, [modalMessage])

  useEffect(()=>{
    setModalMessage(text)
  }, [text])
  
  useEffect(()=>{
    switch (wichModal) {
      case 1:
        setModalContent(
          <form onSubmit={(event)=>onChangeNameCard(event, {Token: data.id, CardName: inputEl.current.value})}>
          <div className={modalStyles.edit_container}>
            <div className={modalStyles.caption}>{editCardNameCaption}</div>
            <div className={modalStyles.text}>{editCardNameText}</div>
    
            <div><input type="text" className={modalStyles.input} defaultValue={data.name}  ref={inputEl} /></div>
            <div className={cls(modalStyles.error, modalStyles.after_input)} ref={error}/>
            <div>
              <button className={cls(modalStyles.button, modalStyles.button_ok)} type="submit">{t('rename')}</button>
              <button className={cls(modalStyles.button, modalStyles.button_cancel)} onClick={onCloseModal}>{t('cancel')}</button>
            </div>
          </div>
          </form>
          )
      break;
      case 2:
        setModalContent(
          <form onSubmit={(event)=>onDeleteCard(event, {CardId: data.id, name: data.name})}>
          <div className={modalStyles.delete_container}>
            <div className={modalStyles.caption}>{deleteCardCaption}</div>
            <div className={modalStyles.card_name}>{data.name}</div>
            <div className={modalStyles.text}>{deleteCardText}</div>
            <div className={modalStyles.error} ref={error}/>
      
            <div>
              <button className={cls(modalStyles.button, modalStyles.button_ok)} onClick={onCloseModal}>{t('no')}</button>
              <button className={cls(modalStyles.button, modalStyles.button_cancel)}  type="submit">{t('yes')}</button>
            </div>
          </div>
          </form>
          )
      break;
      case 3:
        setModalContent(
          <form onSubmit={()=>onCloseModal()}>
          <div className={modalStyles.delete_container}>
            <div className={modalStyles.caption}>{deleteCardResponseCaption} <span className={modalStyles.card_name}>{data.name}</span> {deleteCardResponseText}</div>
            <div className={modalStyles.text}></div>
            <div className={modalStyles.error} ref={error}/>
      
            <div className={cls(modalStyles.center)}>
              <button className={cls(modalStyles.button, modalStyles.button_cancel)}  type="submit">{t('ok')}</button>
            </div>
          </div>
          </form>
        ) 
      break;
      case 4:
        setModalContent(
          <form onSubmit={(event)=>onDeleteAccount(event, {})}>
            <div className={modalStyles.delete_container}>        
              <div className={modalStyles.text}>{t('delete-account-text')}</div>
              <div className={cls(modalStyles.error, modalStyles.center)} ref={error}/>
              <div className={modalStyles.between}>
                <button className={cls(modalStyles.button, modalStyles.button_cancel)} onClick={onCloseModal}>{t('no')}</button>
                <button className={cls(modalStyles.button, modalStyles.button_red)} type="submit">{t('yes')}</button>
              </div>
            </div> 
          </form>
        ) 
      break;
      case 5:
        setModalContent(
          <form onSubmit={()=>onCloseModal()}>
          <div className={modalStyles.delete_container}>
            <div className={modalStyles.text}>{t('delete-account-result')}</div>
            <div className={modalStyles.error} ref={error}/>
      
            <div className={cls(modalStyles.center)}>
              <button className={cls(modalStyles.button, modalStyles.button_cancel)} type="submit">{t('ok')}</button>
            </div>
          </div>
          </form>
        ) 
      break;
      case 6:
        setModalContent(
          <form onSubmit={()=>onCloseModal()}>
          <div className={modalStyles.delete_container}>
            <div className={modalStyles.text}>{t('feedback-result')}</div>
            <div className={modalStyles.error} ref={error}/>
      
            <div className={cls(modalStyles.center)}>
              <button className={cls(modalStyles.button, modalStyles.button_cancel)} type="submit">{t('ok')}</button>
            </div>
          </div>
          </form>
        ) 
      break;
      case 7:
        setModalContent(
          <form onSubmit={()=>onCloseModal()}>
          <div className={modalStyles.delete_container}>
            <div className={modalStyles.caption}>{t('error')}</div>
            <div className={modalStyles.text}></div>
            <div className={modalStyles.error} ref={error}/>
      
            <div className={cls(modalStyles.center)}>
              <button className={cls(modalStyles.button, modalStyles.button_cancel)}  type="submit">{t('ok')}</button>
            </div>
          </div>
          </form>
        ) 
      break;
      case 8:
        setModalContent(
          <form onSubmit={()=>onCloseModal()}>
          <div className={modalStyles.delete_container}>
            
            <div className={modalStyles.text} ref={error} style={{textAlign: 'center', marginBottom: "40px"}}/>
      
            <div className={cls(modalStyles.center)}>
              <button className={cls(modalStyles.button, modalStyles.button_cancel)}  type="submit">{t('ok')}</button>
            </div>
          </div>
          </form>
        )
      break;
      default:
        setModalContent(
          <form onSubmit={()=>onCloseModal()}>
          <div className={modalStyles.delete_container}>
            <div className={modalStyles.caption}>{t('error')}</div>
            <div className={modalStyles.text}></div>
            <div className={modalStyles.error} ref={error} style={{textAlign: 'center', marginBottom: "40px"}}/>
      
            <div className={cls(modalStyles.center)}>
              <button className={cls(modalStyles.button, modalStyles.button_cancel)}  type="submit">{t('ok')}</button>
            </div>
          </div>
          </form>
        ) 
    }
  }, [wichModal, data, onChangeNameCard, onDeleteCard, onCloseModal, onDeleteAccount, t])

  return  <Modal
            isOpen={response}
            onRequestClose={onCloseModal}
            overlayClassName={modalStyles.overlay}
            className={modalStyles.content}
            contentLabel=""
          >
            <button className={modalStyles.close} onClick={onCloseModal}/>
            {
              modalContent
            }
          </Modal>
}
 
export default ModalControl

