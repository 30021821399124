export  const queryConfig = {
  defaultOptions: {
    shared: {
      suspense: true,
    },
    queries: {
      staleTime: 900719925474099,
      cacheTime: 900719925474099,
      refetchOnMount: true,
      refetchOnReconnect: true,
      refetchOnWindowFocus: false,
      queryFn: () => {},
      onSuccess: (data) => {

      }
    }
  }
}
