import React, { useEffect, useState } from 'react'
import styles from '../cards.module.scss'
import CardView from './card'
import TitleView from '../title-view'
import NewCardView from './new-card'

const CardsBlockView = ({data, onCardChange, onAddCard, children}) => {
  const [content, setContent] = useState(null)
  useEffect(()=>{
    if (data && data.ListCard) {
        setContent(data.ListCard.map((item, i)=>{
          return  <CardView 
                      data={item} 
                      key={`card-${i}`} 
                      onCardChange={onCardChange} 
                    />         
        })
        )
    }
    // eslint-disable-next-line
  },[data])
  
  return  (
    <>
    <TitleView className='left'>card-list</TitleView>
    {
      children
    }
    <div className={styles.container}>
      {
        content && content 
      }
      <NewCardView onAddCard={onAddCard}/>
    </div>
    </>
  )
}
 
export default CardsBlockView