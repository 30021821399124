import React from 'react'
import styles from './view.module.scss'
import { useTranslation } from 'react-i18next'
import cls from 'classnames'

const Title = ({className = '', children, ...props}) => {
  const { t } = useTranslation('common')
  return  (
    <h1 className={cls((className ? styles[className]:null), styles.title)}>{t(`common:${children}`)}</h1>
  )
}
 
export default Title