import React from 'react';
import { useRef, useEffect } from 'react';
import { IMaskInput } from 'react-imask';
import styles from './controls.module.scss'

function PhoneField({onChange, value, focused = false}) {
  const inputElement = useRef(null)
  useEffect(() => {
    if (inputElement.current.element && focused) {
      inputElement.current.element.focus();
    }
  }, [inputElement, focused]);

  return (
    <>
    <div className={styles.phone_container}>
      <IMaskInput
        onAccept={(value, mask)=>{
          onChange(value)
        }}
        mask={'000'}
        lazy={false} 
        value={value}
        placeholderChar='_'
        ref={inputElement}
        className={styles.otp} 
        autoComplete='off'
        type='tel'
    />
      
    </div>
    </>

  );
};

export default PhoneField