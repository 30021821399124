import React, { useState, useEffect, useCallback } from 'react'
import cls from 'classnames'
import { useRouter } from '../../utils/useRouter'
import { useTranslation } from 'react-i18next'
import { IMaskInput } from 'react-imask';
import TitleView from '../title-view'
import cardStyles from '../cards.module.scss'
import styles from '../../forms/form.module.scss'
import controlStyles from '../../elements/form-controls/controls.module.scss'

const CheckBlindSum = ({res, callback}) => {
  const { t } = useTranslation(['common'])
  let router = useRouter();
  const [ btnState, setBtnState ] = useState(false);
  const [ error, setError ] = useState({});
  const [ blur, setBlur ] = useState({});
  const [ sum, setSum ] = useState('')

  const validateFields = useCallback((type)=>{
    switch (type) {
      case 'sum':
        setError({...error, [type]:!(String(sum).length === 5)});
      break;
      default:
        throw new Error();
    }
    // eslint-disable-next-line
  }, [sum])

  useEffect(()=>{
    validateFields('sum')
    // eslint-disable-next-line
  }, [sum])

  const handleBlur = (type) => {
    setBlur({...blur, [type]:true});
  }

  useEffect(()=>{
    setBtnState((Object.values(error)).length === 1 && (Object.values(error)).every(elem => elem === false));
  }, [error])

  const handleSaveCard = (event) => {
    event.preventDefault();
    setBtnState(false);
    callback({"OrderId": res.OrderId, "Sum": sum });
  }

  return  (
  <>
    <TitleView>card-approve</TitleView>
    <form onSubmit={handleSaveCard} className={styles.form_container} >
    <div className={`${styles.caption} ${styles.center}`} dangerouslySetInnerHTML={{__html: t('sum-from-sms')}}></div>
      <div className={cls(styles.controls_container)}>
        <div className={cls(controlStyles.phone_container, controlStyles.error)}>
            <IMaskInput
              onAccept={(value, mask)=>{
                setSum(value)
              }}
              name="Sum"
              mask={'00.00'}
              lazy={true} 
              value={sum}
              placeholderChar="_"
              onBlur={()=>{handleBlur('sum')}}
              className={cls(controlStyles.otp, cardStyles.center)} 
              autoComplete='off'
              type="tel"
              autoFocus={true}
            />
        </div>
      </div>
      <div className={cls(cardStyles.add_card_controls)}>
        <button className={cardStyles.button} type="submit" disabled={!btnState}>{t('approve')}</button>
        <span className={cardStyles.back_link} onClick={router.history.goBack}>{t('back')}</span>
      </div>
    </form>
    </>
  )
}
 
export default CheckBlindSum