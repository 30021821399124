import { mpapi, mpapp } from './client'
import { encodeObject } from './utils'

const config = {
  headers: {
    'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
  },
  withCredentials: true
} 

const api = {
  authorizeUser: () => {
    return mpapi.post('/AuthorizeCustomer', null, { withCredentials: true } )
  },

  loginUser: (data) => {
    return mpapi.post('/Login', data, { withCredentials: true })
  },

  sendOTP: (data) => {
    return mpapi.post('/SendOTP', encodeObject({...data}), config)
  },

  checkOTP: (data) => {
    return mpapi.post('/CheckOTP', encodeObject({...data}), config)
  },

  checkOTP2: (data) => {
    return mpapi.post('/CheckOTP2', encodeObject({...data}), config)
  },

  logoutUser: () => {
    return mpapi.post('/Logout', null, config)
  },

  checkSession: () => {
    return mpapi.post('/CheckSession', {}, { withCredentials: true })
  },

  updatePassword: (data) => {
    return mpapi.post('/UpdatePassword', data, { withCredentials: true })
  },

  getServerState: () => {
    return mpapi.post('/GetServerState', null, { withCredentials: true })
  },

  getHTML: (pageName, language) => {
    return mpapp.get(`${process.env.PUBLIC_URL}/locales/`+language+'/'+pageName+'/content.html', null, { })
  }
}

api.mpapi = mpapi

export default api
