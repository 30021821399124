import React, { useState, useEffect, useRef } from 'react';
import { useTimer } from 'react-timer-hook';
import styles from './sms-resend.module.scss'
import stylesOuter from '../../forms/form.module.scss'
import { useTranslation } from 'react-i18next'
import { useGoogleReCaptcha }  from 'react-google-recaptcha-v3';
import ReCAPTCHA from "react-google-recaptcha";

const SMSResend = ({ expiryTimestamp, callback, phone, onResend=()=>{} }) => {
  const {
/*     seconds,
    minutes,
    hours,
    days,
    isRunning,
    start,
    pause,
    resume, */
    restart,
  } = useTimer({ expiryTimestamp: ()=>{
    const time = new Date();
    time.setSeconds(time.getSeconds() + expiryTimestamp);
    return time
  }, onExpire: ()=>onExpire() });
  
  const { t } = useTranslation('common')
  const captchaRef = useRef(null)
  const [show, setShow] = useState(false)
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [ captchaIsChecked, setCaptchaIsChecked ] = useState(false);

  const onClick = async () => {
    setShow(false)
    const token = await captchaRef.current.getValue();
    captchaRef.current && captchaRef.current.reset();
    const time = new Date();
    time.setSeconds(time.getSeconds() + expiryTimestamp);
    if (typeof callback === 'function') {
      //const tok = await executeRecaptcha('homepage');
      callback({"CaptchaV2": token, "Phone": phone.replace(/ /g, '')});
    }
    setCaptchaIsChecked(false)
    onResend();
    restart(time);
  }

  useEffect(()=>{
    if (expiryTimestamp === 1) setShow(true)
    const time = new Date();
    time.setSeconds(time.getSeconds() + expiryTimestamp);
    restart(time);
    // eslint-disable-next-line
  }, [expiryTimestamp])

  const onExpire = () => {
    setShow(true)
  }

  const handleCaptchaV2Success = () => {
    setCaptchaIsChecked(true)
  }

  return  <div className={styles.container}>
            {
              show && <>
              <div className={styles.link} onClick={captchaIsChecked ? onClick:null}>{captchaIsChecked ? t('resend-sms'):t('activate-captcha')}</div>
              <div className={stylesOuter.recaptcha}>
                <ReCAPTCHA
                  sitekey={'6Le5pLcUAAAAAN1bmVILj-Rci3tMJmgvkoU03K1q'} 
                  ref={captchaRef}
                  onChange={handleCaptchaV2Success}
                />
                </div>
              </>
            }
          </div>
}
 
export default SMSResend

