import React from 'react';
import { useRef, useEffect } from 'react';
import { IMaskInput } from 'react-imask';
import styles from './controls.module.scss'

function PhoneField({onChange, value, focused = false}) {

  const inputElement = useRef(null)
  useEffect(() => {
    if (inputElement.current.element && focused) {
      inputElement.current.element.focus();
    }
  }, [inputElement, focused]);

  return (
    <>
    <div className={styles.phone_container}>
      <IMaskInput
        onAccept={(value, mask)=>{
          onChange(value)
        }}
        mask={'\\*\\*\\*\\* \\*\\*\\*\\* \\*\\*00 0000'}
        lazy={false} 
        value={value}
        placeholderChar='_'
        ref={inputElement}
        className={styles.otp} 
        autoComplete='off'
        onComplete={(value, mask) => {
          // tab focus on the next element
          var focussableElements = 'a:not([disabled]), button:not([disabled]), input:not([disabled]), input[type=password]:not([disabled]), input[type=text]:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';
          if (document.activeElement && document.activeElement.form) {
              var focussable = Array.prototype.filter.call(document.activeElement.form.querySelectorAll(focussableElements),
              function (element) {
                  return element.offsetWidth > 0 || element.offsetHeight > 0 || element === document.activeElement
              });
              var index = focussable.indexOf(document.activeElement);
              if(index > -1) {
                 var nextElement = focussable[index + 1] || focussable[0];
                 nextElement.focus();
              }                    
          }
        }}
        type='tel'
    />
      
    </div>
    </>

  );
};

export default PhoneField