export const listPage = `${process.env.PUBLIC_URL}/listcard.html`

export const addPage = `${process.env.PUBLIC_URL}/addcard.html`

export const loginPage = `${process.env.PUBLIC_URL}/`

export const logoutPage = `${process.env.PUBLIC_URL}/logout`

export const checkPage = `${process.env.PUBLIC_URL}/check`

export const termsPage = `${process.env.PUBLIC_URL}/terms.html`

export const privacyPage = `${process.env.PUBLIC_URL}/privacy.html`

export const feedbackPage = `${process.env.PUBLIC_URL}/feedback.html`
