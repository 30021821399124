import React from 'react';
import schema from '../../../schemas/templates/common';

const CopyrightString = ({ t }) => {

  const currentYear = new Date().getFullYear() !== schema.start_year ? ' - ' + new Date().getFullYear():''
  
  return (
    <div>{`© ${schema.start_year}${currentYear} ${schema.main_name}`}</div> 
  )
}
 
export default React.memo(CopyrightString);
