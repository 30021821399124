import React from 'react'
import styles from './controls.module.scss'
import cls from 'classnames'

const ErrorField = ({ text, error, variant = '' }) => {
  return (
    <>
    {
      error && <div className={cls(styles.error_text, variant ? styles[variant]:'mt-4')} dangerouslySetInnerHTML={{__html: text}}/>
    }
    
    </>
  );
};
export default ErrorField