import { useMutation, useQuery, useQueryClient  } from 'react-query'
import * as DBApi from './db-api'


export function useDelete(data) {
  const queryCache = useQueryClient();
  return useMutation((data)=>DBApi.general.deleteCard(data), {
    
    onSuccess: (data, variables) => {
      queryCache.refetchQueries(["Cards"])
    },
    onError: () => {

    },
    useErrorBoundaries: true
  },

  );
}

export function useChangeNameCard(data) {
  const queryCache = useQueryClient();
  return useMutation((data)=>DBApi.general.changeNameCard(data), {
    
    onSuccess: (data, variables) => {
      queryCache.refetchQueries(["Cards"])
    },
    onError: () => {

    },
    useErrorBoundaries: true
  },

  );
}

export function useGet() {
  return useQuery('Cards', DBApi.general.getCards, {enabled: false, retry: false, retryOnMount: false, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false});
}

export function useDeleteAccount() {
  const queryCache = useQueryClient()
  return useMutation(DBApi.general.deleteAccount, {
    onSuccess: (data, variables) => {
      queryCache.invalidateQueries()
      queryCache.setQueryData('Authorized', !data.Success)
    },
    onError: () => {

    },
    useErrorBoundaries: true
  });
}

export function useSendFeedback(data) {

  return useMutation((data)=>DBApi.general.sendFeedback(data), {
    
    onSuccess: (data, variables) => {

    },
    onError: () => {

    },
    useErrorBoundaries: true
  });
}

export function useSaveCard(data) {

  return useMutation((data)=>DBApi.general.saveCard(data), {
    
    onSuccess: (data, variables) => {

    },
    onError: () => {

    },
    useErrorBoundaries: true
  });
}

export function useCheckNon3DSCard(data) {

  return useMutation((data)=>DBApi.general.checkNon3DSCard(data), {
    
    onSuccess: (data, variables) => {

    },
    onError: () => {

    },
    useErrorBoundaries: true
  });
}
