import { useState } from "react";

const useInput = (initialValue) => {
    const [value, setValue] = useState(initialValue);

    const handleChange = (value) => {
        setValue(value);
    };

    return {
        value,
        onChange: handleChange
    };
};

export default useInput;
