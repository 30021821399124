import React, { useState, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import * as Queries from '../../queries/cards'  
import Loader from '../../components/elements/loader-vintage'
import { useHistory } from 'react-router-dom'
import CardAddBlockView from '../../components/view/cards/add'
import ErrorField from '../../components/elements/form-controls/error-field'
import Process3DS from '../../components/utils/process3DS'
import CheckBlindSum from '../../components/view/cards/check-blind-sum'
import ModalControl from '../../components/organizms/modal-control'

const CardAddPageContainer = ({...props}) => {
  const router = useHistory()
  const { t } = useTranslation();
  
  const [ error, setError] = useState({show: false, text: ''})
  
  const [ response, setResponse ] = useState(false)
  const [ text, setText] = useState('')

  const [ dataThreeDS, setDataThreeDS ] = useState(null)
  const [ dataNonThreeDS, setDataNonThreeDS ] = useState(null)

  const { mutate: saveCard, isLoading: saveCardIsLoading} = Queries.useSaveCard()
  const { mutate: checkNon3DSCard, isLoading: checkNon3DSCardIsLoading } = Queries.useCheckNon3DSCard()

  const redirectAfterSuccessLogin = useCallback((res) => {
    switch (res.Message) {
      case 'ThreeDS':
        setDataThreeDS(res);
        break;
      case 'NonThreeDS':
        setDataNonThreeDS(res)      
        break;
      case 'FirstActivate':
      case 'Activate':
        router.push( {
          pathname: `${process.env.PUBLIC_URL}/listcard.html`,
          query: { 
            Message: encodeURIComponent(res.Message),
            Success: 'true'
          },
        })
        break;
      default:
        setDefaultResponse(true, t(`common:error-card-add`))
    }    
  }, [router, t])

  const handleSaveCard = (data) => {
    setError({show: false, text: ''})
    return new Promise(()=>saveCard(data, {
      onSuccess: (res) => {
        if (res.Success) {
          redirectAfterSuccessLogin(res)
        } else {
          setDefaultResponse(true, (t(`common:error-card-add`)/*  + (res.ErrCodeProcessing ? "<br/>" + t(`mapping-errors:${res.ErrCodeProcessing}`):"") */))
          //setError({show: true, text: t(`common:error-card-add`) + (res.ErrCodeProcessing ? "<br/>" + t(`mapping-errors:${res.ErrCodeProcessing}`):"")}) 
        }
      },
      onError: () => {
        setDefaultResponse(true, t(`common:error-card-add`))
      },
    })) 
  }

  const handleCheckNon3DSCard = (data) => {
    setError({show: false, text: ''})
    return new Promise(()=>checkNon3DSCard(data, {
      onSuccess: (res) => {
        if (res.Success) {
          router.push( {
            pathname: `${process.env.PUBLIC_URL}/listcard.html`,
            query: { 
              Message: encodeURIComponent(res.Message),
              Success: 'true'
            },
          })
        } else {
          router.push( {
            pathname: `${process.env.PUBLIC_URL}/listcard.html`,
            query: { 
              Message: encodeURIComponent(res.ErrCode),
              Success: 'false'
            },
          }) 
        }
      },
      onError: () => {
        setDefaultResponse(true, t(`common:error-card-add`))
      },
    })) 
  }

  const setDefaultResponse = (response = false, error = '') => {
    setResponse(response)
    setText(error);
  }

  const handleCloseModal = () => {
    setResponse(false)
    setText('')
  }
  
  return (
          <>
            {
              !dataThreeDS && !dataNonThreeDS &&<CardAddBlockView 
                                                    onSaveCard={handleSaveCard}
                                                  >
                                                    <ErrorField text={error.text} error={error.show} variant='under_title'/>
                                                  </CardAddBlockView>
            }
            {
              dataThreeDS && <Process3DS res={dataThreeDS} />
            }
            {
               dataNonThreeDS && <CheckBlindSum res={dataNonThreeDS} callback={handleCheckNon3DSCard}/>
            }
            <ModalControl
              response={response}
              wichModal={-1}
              text={text}
              data={''}
              onCloseModal={handleCloseModal}
            />
            <Loader enabled={saveCardIsLoading||checkNon3DSCardIsLoading}/>
          </>
  )
}

export default CardAddPageContainer
