import { mpapi } from "./client";
import { encodeObject } from "./utils";

const config = {
  headers: {
    "content-type": "application/x-www-form-urlencoded;charset=utf-8",
  },
  withCredentials: true,
};

const api = {
  getCards: () => {
    return mpapi.post("GetCardsHardWeb", null, config);
  },
  deleteCard: (data) => {
    return mpapi.post("DeleteCard", encodeObject(data), config);
  },
  changeNameCard: (data) => {
    return mpapi.post("EditCardName", encodeObject(data), config);
  },
  deleteAccount: () => {
    return mpapi.post("DeleteMSISDN", null, config);
  },
  sendFeedback: (data) => {
    return mpapi.post("SendEmail", encodeObject(data), config);
  },
  saveCard: (data) => {
    return mpapi.post("SaveCard", encodeObject(data), config);
  },
  checkNon3DSCard: (data) => {
    return mpapi.post("ActivateCard", encodeObject(data), config);
  },
  getAvailablePhoneNumbers: () => {
    return mpapi.post("GetAvailableNumbers", null, config);
  },
};

api.mpapi = mpapi;

export default api;
