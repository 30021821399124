import React from 'react'
import styles from './form.module.scss'
import { useTranslation } from 'react-i18next'

const Caption = ({item, phone = '', ...props}) => {
  const { t } = useTranslation();
  return  (
    <>
    {
      item.caption && <div 
                className={`${styles.caption} ${item.className ? styles[item.className]:''}`} 
                dangerouslySetInnerHTML={{ __html: (item.caption ? t(item.caption):'') + (item.phone ? ' +' + phone:'')}}/>
    }
    </>
    
  )
}
 
export default Caption