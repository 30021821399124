import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuthorization } from '../components/utils/withAuthorization'
import { loginPage } from './prepare'
import Layout from '../components/layouts'

const PrivateRoute = ({component: Component, fullHeight=true, ...rest}) => {
    const isAuthorized = useAuthorization()
    return (
        <Route {...rest} render={props => (
            isAuthorized ?
            <Layout isAuthorized={isAuthorized} fullHeight={fullHeight}><Component {...props} /></Layout>
            : (isAuthorized === false ? <Redirect to={loginPage} />:null)
        )} />
    );
};

export default PrivateRoute;
