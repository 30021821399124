import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-pluralrules/locale-data/ru'

import './styles/globals.scss'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/100.css'

import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider, QueryClient } from 'react-query'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { CookiesProvider } from 'react-cookie';

import { queryConfig } from './queries/config';
import './translates/i18n';

import App from './App';

const queryClient = new QueryClient(queryConfig);

ReactDOM.render(
  <React.StrictMode>
    <GoogleReCaptchaProvider reCaptchaKey="6Lfs5bMUAAAAAFZzNrcJCQkv_xu2YJI2OElEOmeI">
      <CookiesProvider>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </CookiesProvider>
    </GoogleReCaptchaProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
