import React, { useEffect, useState, useCallback } from 'react'
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { IMaskInput } from 'react-imask';
import IMask from "imask";
import cls from 'classnames'
import luhn from "luhn";

import TitleView from '../title-view'

import cardFromNumber from '../../utils/payment'

import styles from '../cards.module.scss'

const Patterns = {
  'cvc': /\d{3,4}/,
  'date' : /\d\d\/\d\d/,
  'name' : /.{0,}/,
  'pan' : /[\d| ]{16,22}/,
}

const EmptyCardView = ({onSaveCard, children}) => {
  const { t } = useTranslation(['common'])
  let history = useHistory();
  const [ btnState, setBtnState ] = useState(false);
  const [ error, setError ] = useState({});
  const [ blur, setBlur ] = useState({});

  const [ isShowTip, setIsShowTip] = useState(false)
  const [ isClicked, setIsClicked] = useState(false)

  const [ psLogo, setPSLogo] = useState(null)

  const [ cvc, setCVC ] = useState('')
  const [ pan, setPAN ] = useState('')
  const [ date, setDate ] = useState('')
  const [ name, setName ] = useState('')
  
  const handleHoverOnTip = (event, isHovered) => {
    if (event.type === 'click' && !isClicked) {
      setIsClicked(true);
      return false;
    } else {
     setIsShowTip(isHovered);      
    }
  }

  const validateFields = useCallback((type)=>{
    switch (type) {
      case 'pan':
        setPSLogo(cardFromNumber(pan));
        setError({...error, [type]:!luhn.validate(pan.replace(/ /g, ''))});
      break;
      case 'cvc':
        setError({...error, [type]:!Patterns[type].test(cvc)});
      break;
      case 'date':
        let dateArray = date.split('/');
        setError({...error, [type]:!Patterns[type].test(date)/*  || 
          !(parseInt(dateArray[0]) >= new Date().getMonth()+1 || parseInt(dateArray[1]) > parseInt(String((new Date()).getFullYear()).substr(-2))) */
        });
      break;
      case 'name':
        setError({...error, [type]:!Patterns[type].test(name)});
      break;
      default:
        throw new Error();
    }
    // eslint-disable-next-line
  }, [pan, cvc, date, name])

  useEffect(()=>{
    validateFields('pan')
    // eslint-disable-next-line
  }, [pan])

  useEffect(()=>{
    validateFields('cvc')
    // eslint-disable-next-line
  }, [cvc])

  useEffect(()=>{
    validateFields('date')
    // eslint-disable-next-line
  }, [date])

  useEffect(()=>{
    validateFields('name')
    // eslint-disable-next-line
  }, [name])

  const handleBlur = (type) => {
    setBlur({...blur, [type]:true});
  }

  useEffect(()=>{
    setBtnState((Object.values(error)).length === 4 && (Object.values(error)).every(elem => elem === false));
  }, [error])

  const handleSaveCard = (event) => {
    event.preventDefault();
    setBtnState(false);
    onSaveCard({"Number": pan.replace(/ /g, ''), "CardHolder": "", "CVV": cvc, "ExpMonth": date.split('/')[0], "ExpYear": date.split('/')[1], "CardName": name});
  }

  return  (
    <>
    <TitleView className='left'>card-add</TitleView>
    {
      children
    }
    <form onSubmit={handleSaveCard}>
    <div className={cls(styles.container, styles.new)}>
      <div className={cls(styles.card, styles.new)}>
        <div className={styles.cover}>
          <div className={cls(styles.pan, styles.block)}>
            <div className={styles.label}>{t('input-pan')}</div>
            <IMaskInput
                onAccept={(value, mask)=>{
                  setPAN(value)
                }}
                name="CardNumber"
                mask={[
                  {
                    mask: '0000 0000 0000 {0000} {000}',
                    startsWith: '5018',
                    lazy: true,
                    ps: 'maestro'
                  },
                  {
                    mask: '0000 0000 0000 {0000} {000}',
                    startsWith: '502',
                    lazy: true,
                    ps: 'maestro'
                  },
                  {
                    mask: '0000 0000 0000 {0000} {000}',
                    startsWith: '503',
                    lazy: true,
                    ps: 'maestro'
                  },
                  {
                    mask: '0000 0000 0000 {0000} {000}',
                    startsWith: '506',
                    lazy: true,
                    ps: 'maestro'
                  },
                  {
                    mask: '0000 0000 0000 {0000} {000}',
                    startsWith: '56',
                    lazy: true,
                    ps: 'maestro'
                  },
                  {
                    mask: '0000 0000 0000 {0000} {000}',
                    startsWith: '58',
                    lazy: true,
                    ps: 'maestro'
                  },
                  {
                    mask: '0000 0000 0000 {0000} {000}',
                    startsWith: '639',
                    lazy: true,
                    ps: 'maestro'
                  },
                  {
                    mask: '0000 0000 0000 {0000} {000}',
                    startsWith: '6220',
                    lazy: true,
                    ps: 'maestro'
                  },
                  {
                    mask: '0000 0000 0000 {0000} {000}',
                    startsWith: '67',
                    lazy: true,
                    ps: 'maestro'
                  },
                  {
                    mask: '0000 0000 0000 0000 {000}',
                    startsWith: '220',
                    lazy: true,
                    ps: 'mir'
                  },
                  {
                    mask: '0000 000000 00000',
                    startsWith: '34',
                    lazy: true,
                    ps: 'amex'
                  },
                  {
                    mask: '0000 000000 00000',
                    startsWith: '37',
                    lazy: true,
                    ps: 'amex'
                  },
                  
                  {
                    mask: '0000 0000 0000 0000',
                    startsWith: '',
                    ps: 'other'
                  }
                ]}
                dispatch={ function (appended, dynamicMasked) {
                  var number = (dynamicMasked.value + appended).replace(/\D/g,'');
            
                  return dynamicMasked.compiledMasks.find(function (m) {
                    return number.indexOf(m.startsWith) === 0;
                  });
                }}
                lazy={true} 
                value={pan}
                placeholder='0000 0000 0000 0000'
                onBlur={()=>{handleBlur('pan')}}
                onComplete={(value, mask) => {
                    var focussableElements = 'a:not([disabled]), button:not([disabled]), input:not([disabled]), input[type=password]:not([disabled]), input[type=text]:not([disabled]), input:not([tabindex="-1"])';
                    if (document.activeElement && document.activeElement.form) {
                        var focussable = Array.prototype.filter.call(document.activeElement.form.querySelectorAll(focussableElements),
                        function (element) {
                            return element.offsetWidth > 0 || element.offsetHeight > 0 || element === document.activeElement
                        });
                        var index = focussable.indexOf(document.activeElement);
                        if(index > -1) {
                          var counter = 1;
                          if (focussable[index + counter] && focussable[index + counter].name === 'CardName') {
                            counter = 2;
                          }
                          var nextElement = focussable[index + counter] || focussable[0];
                          nextElement.focus();
                        }                    
                    }           
                }}
                className={cls(styles.input, psLogo && styles[psLogo])} 
                autoComplete='cc-number'
                autoFocus={true}
                type="text"
              />
            <div className={cls(styles.error_on_card, blur['pan']&&error['pan']&&styles.visible)}>{t('error-pan')}</div>
          </div>
          <div className={cls(styles.name, styles.block)}>

            <div className={cls(styles.label)}>
              {t('input-name')}
              <span 
                onClick={(event) => handleHoverOnTip(event, !isShowTip)} 
                onMouseOver={(event) => handleHoverOnTip(event, true)} 
                onMouseLeave={(event) => handleHoverOnTip(event, false)} 
                className={styles.tip}
              >
                {
                  isShowTip && <div className={styles.tip_content}>
                                {t('tip-text')}
                                <i></i>
                              </div>
                }                
              </span>
            </div>
            <IMaskInput
                onAccept={(value, mask)=>{
                  setName(value)
                }}
                name="CardName"
                tabIndex={-1}
                mask={String}
                lazy={true} 
                value={name}
                placeholder={t('card-name')}
                onBlur={()=>{handleBlur('name')}}
                className={cls(styles.input)} 
                autoComplete='cc-name'
                type="text"
              />
            <div className={cls(styles.error_on_card, blur['name']&&error['name']&&styles.visible)}>{t('error-name')}</div>
          </div>
          <div className={cls(styles.blocks_container)}>
            <div className={cls(styles.date, styles.block)}>
              <div className={styles.label}>{t('input-date')}</div>
              {/* <input className={styles.input} name="EDate"/> */}
              <IMaskInput
                onAccept={(value, mask)=>{
                  setDate(value)
                }}
                name="EDate"
                mask={'MM/YY'}
                blocks={{
                  MM: {
                    mask: [
                      {
                        mask: IMask.MaskedRange,
                        from: 1,
                        to: 12,
                        startsWith: '1',
                        placeholderChar: 'M',
                        maxLength: 2,
                      },
                      {
                        mask: '[\\0]0',
                        startsWith: '2',
                        from: 1,
                        to: 12,
                        placeholderChar: 'M',
                        maxLength: 2,
                      },
                      {
                        mask: '{\\0}0',
                        startsWith: '3',
                        from: 1,
                        to: 12,
                        placeholderChar: 'M',
                        maxLength: 2,
                        
                      },
                      {
                        mask: '{\\0}0',
                        startsWith: '4',
                        from: 1,
                        to: 12,
                        placeholderChar: 'M',
                        maxLength: 2,
                        
                      },
                      {
                        mask: '{\\0}0',
                        startsWith: '5',
                        from: 1,
                        to: 12,
                        placeholderChar: 'M',
                        maxLength: 2,
                        
                      },
                      {
                        mask: '{\\0}0',
                        startsWith: '6',
                        from: 1,
                        to: 12,
                        placeholderChar: 'M',
                        maxLength: 2,
                        
                      },
                      {
                        mask: '{\\0}0',
                        startsWith: '7',
                        from: 1,
                        to: 12,
                        placeholderChar: 'M',
                        maxLength: 2,
                        
                      },
                      {
                        mask: '{\\0}0',
                        startsWith: '8',
                        from: 1,
                        to: 12,
                        placeholderChar: 'M',
                        maxLength: 2,
                       
                      },
                      {
                        mask: '{\\0}0',
                        startsWith: '9',
                        from: 1,
                        to: 12,
                        placeholderChar: 'M',
                        maxLength: 2,
                       
                      },
                      {
                        mask: IMask.MaskedRange,
                        from: 1,
                        to: 9,
                        startsWith: '0',
                        placeholderChar: 'M',
                        maxLength: 2,
                      },
                      {
                        mask: '00',
                        startsWith: '',
                        from: 1,
                        to: 12,
                        placeholderChar: 'M',
                        maxLength: 2,
                      }
                    ],
                    dispatch: function (appended, dynamicMasked) {
                      var number = (dynamicMasked.value + appended).replace(/\D/g,'');
                      return dynamicMasked.compiledMasks.find(function (m) {
                        return number.indexOf(m.startsWith) === 0;
                      });
                    }            
                  },
                  YY: {
                    mask: IMask.MaskedRange,
                    from: 19,// parseInt(String((new Date()).getFullYear()).substr(-2)),
                    to: 99,
                    maxLength: 2,
                    placeholderChar: 'Y'
                  }
                }}
                lazy={true} 
                value={date}
                placeholder='MM/YY'
                onBlur={()=>{handleBlur('date')}}
                onComplete={(value, mask) => {
                  var focussableElements = 'a:not([disabled]), button:not([disabled]), input:not([disabled]), input[type=password]:not([disabled]), input[type=text]:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';
                  if (document.activeElement && document.activeElement.form) {
                      var focussable = Array.prototype.filter.call(document.activeElement.form.querySelectorAll(focussableElements),
                      function (element) {
                          return element.offsetWidth > 0 || element.offsetHeight > 0 || element === document.activeElement
                      });
                      var index = focussable.indexOf(document.activeElement);
                      if(index > -1) {
                        var nextElement = focussable[index + 1] || focussable[0];
                        nextElement.focus();
                      }                    
                  }           
                }}
                className={cls(styles.input)} 
                autoComplete='cc-expiry'
                type="tel"
              />




              <input id="EMonth" name="EMonth" type="hidden"/>
              <input id="EYear" name="EYear" type="hidden"/>
              <div className={cls(styles.error_on_card, blur['date']&&error['date']&&styles.visible)}>{t('error-date')}</div>
            </div>
            <div className={cls(styles.cvv, styles.block, "hidden-sm-up")}>
              <div className={styles.label}>{t('input-cvc')}</div>
              <IMaskInput
                onAccept={(value, mask)=>{
                  setCVC(value)
                }}
                name="SecureCode"
                mask={'000'}
                lazy={true} 
                value={cvc}
                placeholder='CVC'
                onBlur={()=>{handleBlur('cvc')}}
                onComplete={(value, mask) => {
                  var focussableElements = 'a:not([disabled]), button:not([disabled]), input:not([disabled]), input[type=password]:not([disabled]), input[type=text]:not([disabled]), input:not([tabindex="-1"])';
                  if (document.activeElement && document.activeElement.form) {
                      var focussable = Array.prototype.filter.call(document.activeElement.form.querySelectorAll(focussableElements),
                      function (element) {
                          return element.offsetWidth > 0 || element.offsetHeight > 0 || element === document.activeElement
                      });
                      var index = focussable.indexOf(document.activeElement);
                      if(index > -1) {
                        var nextElement = focussable[index + 1] || focussable[0];
                        nextElement.focus();
                      }                    
                  }           
                }}
                className={cls(styles.input, styles.center)} 
                autoComplete='cc-csc'
                type="password"
              />
              <div className={cls(styles.error_on_card, blur['cvc']&&error['cvc']&&styles.visible)}>{t('error-cvc')}</div>
            </div>
          </div>
        </div>
      </div>
      <div className={cls(styles.card, styles.new, styles.cvv)}>
        <div className={styles.cover}>
          <div className={cls(styles.cvv, styles.block)}>
            <div className={styles.label}>{t('input-cvc')}</div>
            <IMaskInput
              onAccept={(value, mask)=>{
                setCVC(value)
              }}
              name="SecureCode"
              mask={'000'}
              lazy={true} 
              value={cvc}
              placeholder='CVC'
              onBlur={()=>{handleBlur('cvc')}}
              onComplete={(value, mask) => {
                var focussableElements = 'a:not([disabled]), button:not([disabled]), input:not([disabled]), input[type=password]:not([disabled]), input[type=text]:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';
                if (document.activeElement && document.activeElement.form) {
                    var focussable = Array.prototype.filter.call(document.activeElement.form.querySelectorAll(focussableElements),
                    function (element) {
                        return element.offsetWidth > 0 || element.offsetHeight > 0 || element === document.activeElement
                    });
                    var index = focussable.indexOf(document.activeElement);
                    if(index > -1) {
                      var nextElement = focussable[index + 1] || focussable[0];
                      nextElement.focus();
                    }                    
                }           
              }}
              className={cls(styles.input, styles.center)} 
              autoComplete='cc-csc'
              type="password"
            />
            <div className={cls(styles.error_on_card, blur['cvc']&&error['cvc']&&styles.visible)}>{t('error-cvc')}</div>
          </div>
        </div>
      </div>  
      <div className={cls(styles.card, styles.filler)}/>     
    </div>
    <div className={cls(styles.add_card_controls)}>
      <button className={styles.button} type="submit" disabled={!btnState}>{t('add-card')}</button>
      <span className={styles.back_link} onClick={history.goBack}>{t('back')}</span>
    </div>
    </form>
    </>
  )
}

export default EmptyCardView
