import React, { useEffect, useState } from 'react'

import cardFromNumber from '../../utils/payment'
import { format } from '../../utils/dateFormat'

import styles from '../cards.module.scss'

import VisaLogo from  '../../../assets/img/logos/visa.svg'
import MastercardLogo from  '../../../assets/img/logos/mastercard.svg'
import MaestroLogo from  '../../../assets/img/logos/maestro.svg'
import AMEXLogo from  '../../../assets/img/logos/amex.png'

const logos = {
  "visa": VisaLogo,
  "mastercard": MastercardLogo,
  "maestro": MaestroLogo,
  "amex": AMEXLogo
}
 
const CardView = ({data, onCardChange}) => {
  
  const handleCardEdit = (token, name) => {
    onCardChange({id: token, name: name}, 1)
  }

  const handleCardDelete = (cardId, name) => {
    onCardChange({id: cardId, name: name}, 2)
  }

  return  (
    <>
    {
      data && <div className={`${styles.card}`}>
      <div className={styles.name}>{getCardName(data)}</div>
      
      <div className={styles.cover}>
        <CardCover data={data}/>
      </div> 

      <div className={styles.controls}>
        <div className={styles.edit} onClick={()=>handleCardEdit(data.Key, getCardName(data))}></div>
        <div className={styles.delete} onClick={()=>handleCardDelete(data.CardId, getCardName(data))}></div>
      </div>
    </div>
    }
    </>
  )
}

const CardContent = ({data, isLogoVisible}) => {
  const logo = logos[cardFromNumber(data.PANBank)];
  return (
    <div className={styles.info_container}>
      <div className={styles.pan} style={(data.FgColor ? {color: data.FgColor}:{})}>{getMaskedPAN(data.PANMask)}</div>
      <div className={styles.date} style={(data.FgColor ? {color: data.FgColor}:{})}>{parseMVCDate(data.ExpDate, 'mm/yy')}</div>
      {
        <div className={styles.logo}>{logo && isLogoVisible && <img src={logo} alt="" />}</div>
      }
      {/* <div className={styles.holder}>{data.CardHolder}</div> */}
    </div>
  )
}

/* const emptySrc = 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAzIiBoZWlnaHQ9IjE0NCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2ZXJzaW9uPSIxLjEiLz4='; */

const CardCover = ({data}) => {
  
  const [visibility, setVisibility] = useState('none')
  const [cover, setCover] = useState(null)
  
  const handleError = (element) => {
    
    element.onload = null;
    element.onerror = null;

    //element.src = emptySrc;

    element.parentNode.removeChild(element);
    setVisibility('error');
  }

  useEffect(()=>{
    const getCardCoverImg = () => {
      return <img src={'/'+data.CardBackgroundCombinedAssetPath+'.jpeg'} alt="" onError={(el)=>handleError(el.target)}/>
    }
/*       const getDefaultCardCoverImg = () => {
      return <img alt="" src={emptySrc} />
    } */
    

    if (data.CardBackgroundCombinedAssetPath !== '') {
      setVisibility('visible')
      setCover(getCardCoverImg())
    } else {
      setVisibility('error')
      setCover()
    }
  }, [data, visibility])

  return (
    <>
      <>{cover}<CardContent data={data} isLogoVisible={visibility === 'error'}/></>
    </>
  )
}

const getMaskedPAN = (pan) => pan.substring(pan.length-5).replace('x', '*');
const getCardName = (data) => data.CardName !== '' ? data.CardName:capitalize(cardFromNumber(data.PANBank)) + ' ' +getMaskedPAN(data.PANMask)
const capitalize = (string) => string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

function parseMVCDate(mvcDate, pattern) {
	if (typeof pattern == 'undefined')
		pattern = "dd.MM.yyyy HH:mm";

	if (mvcDate && mvcDate.length > 0 && mvcDate !== '/Date(-62135596800000)/') {

		if (pattern === "timestamp") {
      return parseInt(mvcDate.replace(/\/Date\((\d+)\)\//gi, "$1"));
    }

		return format(new Date(parseInt(mvcDate.replace(/\/Date\((\d+)\)\//gi, "$1"))), pattern);
	} else {
		return pattern === "timestamp" ? 0 : "";
	}
}

export default CardView
