import styles from '../../form.module.scss'
import schema from '../../../../schemas/forms/auth/second-otp'
import OTPField from '../../../elements/form-controls/otp-field'
import CVVField from '../../../elements/form-controls/cvv-field'
import MaskedPANField from '../../../elements/form-controls/masked-pan-field'
import { useState, useEffect } from 'react';
import Caption from '../../control-caption'
import SMSResend from '../../../organizms/sms-resend'
import useInput from '../../control-state-hook'

const Form = ({t, phone, smsSendCallback, error = false, setError, ...props}) => {
  const [ btnState, setBtnState ] = useState(false);
  const otp = useInput('');
  const pan = useInput('');
  const cvv = useInput('');
  const [ expiryTimestamp, setExpiryTimestamp ] = useState(60)

  const handleSubmit = (event) => {
    event.preventDefault();
    setBtnState(false);
    props.callback({"Phone": phone, "OTP": otp.value, 'CardNumber': pan.value.replace(/( )|_|\*/g, ''), 'CVV': cvv.value});
  }
  
  useEffect(()=>{
    if(otp.value.replace(/_/g, '').length === parseInt(process.env.REACT_APP_OTP_LENGTH) && cvv.value.replace(/_/g, '').length === 3 && pan.value.replace(/( )|_|\*/g, '').length === 6) {
      setBtnState(true)
    } else {
      setBtnState(false)
    }
  }, [otp.value, cvv.value, pan.value])

  useEffect(()=>{
    if (error) {
      setExpiryTimestamp(1)
      setError(false)
    } else {
      setExpiryTimestamp(60)
    }
    // eslint-disable-next-line
  }, [error])

  const handleClearInput = () => {
    otp.onChange('');
  }

  return (
    <>
      <form className={styles.form_container} onSubmit={handleSubmit}>
        <div className={styles.controls_container}>
        {
          schema().fields.map((i, idx)=>{
            let control = null;
            switch(i.component.toLocaleLowerCase()) {
              case 'otp-field':
                control = <OTPField {...otp}/>
              break;
              case 'cvv-field':
                control = <CVVField {...cvv}/>
              break;
              case 'masked-pan-field':
                control = <MaskedPANField {...pan}/>
              break;
              default:
                control = null;
            }
            return  <div key={`field-${idx}`}>
                      <Caption item={i} phone={phone}/>
                      {control}
                    </div>
          })
        } 
        </div>
        <div className={`${styles.btn} `}>
          <button type='submit' disabled={!btnState}>{t(schema().submitLabel)}</button>
        </div>      
      </form>
      <SMSResend  expiryTimestamp={expiryTimestamp} onResend={handleClearInput} callback={smsSendCallback} phone={phone} />
    </>
  )
}
export default Form
