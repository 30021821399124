import React from 'react'
import { withTranslation } from 'react-i18next'
import { useHistory } from "react-router-dom"

const NotFound = ({t}) => {
  let history = useHistory();

  let back = e => {
    e.stopPropagation();
    history.goBack();
  };

  return <button onClick={back}>
              {t('common:back')}
            </button>
}
 
export default React.memo(withTranslation(['common'])(NotFound))
