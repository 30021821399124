import styles from '../../form.module.scss'
import schema from '../../../../schemas/forms/auth/otp'
import PhoneField from '../../../elements/form-controls/phone-field';
import OTPField from '../../../elements/form-controls/otp-field'
import { useState, useEffect } from 'react';
import SMSResend from '../../../organizms/sms-resend'

const Form = ({t, phone, smsSendCallback, error = false, setError, ...props}) => {
  const [ btnState, setBtnState ] = useState(false);
  const [ inputValue, setInputValue ] = useState('');
  const [ expiryTimestamp, setExpiryTimestamp ] = useState(60)

  const handleSubmit = (event) => {
    event.preventDefault();
    setBtnState(false);
    props.callback({"Phone": phone, "OTP": inputValue.replace(/_/g, '')});
  }

  const handleChange = (value) => {
    setInputValue(value);
    checkError(value);
  }

  const handleClearInput = () => {
    setInputValue('');
  }

  const checkError = (value) => {
    let preparedValue = value.replace(/_/g, '')
    if(preparedValue.length === parseInt(process.env.REACT_APP_OTP_LENGTH)) {
      setBtnState(true)
    } else {
      setBtnState(false)
    }
  }

  useEffect(()=>{
    if (error) {
      setExpiryTimestamp(1)
      setError(false)
    } else {
      setExpiryTimestamp(60)
    }
    // eslint-disable-next-line
  }, [error])

  return (
    <>
      <form className={styles.form_container} onSubmit={handleSubmit}>
      <div className={`${styles.caption} ${styles.center}`} dangerouslySetInnerHTML={{__html: `${t(schema().title)} +${phone}`}}></div>
      <div className={styles.controls_container}>
      {
        schema().fields.map((i, idx)=>{
          let control = null;
          switch(i.component) {
            case 'phone-field':
              control =  <PhoneField onChange={handleChange} value={inputValue} key={`field-${idx}`}/>
            break;
            case 'otp-field':
              control = <OTPField autoFocus={true} onChange={handleChange} value={inputValue} key={`field-${idx}`}/>
            break;
            default:
              control = null;
          }
          return control;
        })
      } 
      </div>
      <div className={`${styles.btn} `}><button type='submit' disabled={!btnState}>{t(schema().submitLabel)}</button></div>      
      </form>
      <SMSResend  expiryTimestamp={expiryTimestamp} onResend={handleClearInput} callback={smsSendCallback} phone={phone}/>
    </>
  )
}
export default Form

