import React from 'react'
import TitleView from '../title-view.js'
import { useTranslation } from 'react-i18next'
import Loader from '../../../components/elements/loader-vintage'
import Styles from "./feedback.module.scss" 

const FeedbackBoxView = ({callback, onDeleteAccount, response, ...props}) => {
  const { t, i18n } = useTranslation()

  return  (
    <>
    <div className={Styles.caption}>
    {
    i18n.resolvedLanguage === 'ru' ? 
      <>Если Вы не нашли ответ на свой вопрос в разделе <a href="https://masterpass.com/ru-ru/faqs/about-masterpass.html" target="_blank" rel="noreferrer">“Часто задаваемые вопросы”</a>, то можете воспользоваться формой обратной связи. Мы ответим Вам в ближайшее время.</>:
      <>Если Вы не нашли ответ на свой вопрос в разделе <a href="https://masterpass.com/ru-ru/faqs/about-masterpass.html" target="_blank" rel="noreferrer">“Часто задаваемые вопросы”</a>, то можете воспользоваться формой обратной связи. Мы ответим Вам в ближайшее время.</>
    }  
    </div>    
    <TitleView t={t}>feedback</TitleView>
    <props.form t={t} callback={callback} onDeleteAccount={onDeleteAccount} response={response} {...props}/>
    <Loader enabled={props.loading}/>
    </>
  )      
}
 
export default FeedbackBoxView