import React from 'react';
import styles from './controls.module.scss'
import cls from 'classnames'

function TextAreaField({onChange, value, classError = '', ...props}) {
  return (
    <>
    <div className={cls(classError, styles.phone_container)}>
      <textarea 
        onChange={(event)=>onChange(event.target.value)}
        value={value}
        className={styles.text} 
        {...props}
    />
      
    </div>
    </>

  );
};

export default TextAreaField